import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './account-balance-widget.html';

import { AccountTransactionsWidget } from '../account-transactions-widget';

import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';
import Format from '../../../interfaces/Format';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        date: formatProvider.date(),
        euro: formatProvider.euro(),
        euroSpecial: formatProvider.euroSpecial(),
    },
    props: {
        isActiveWidget: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AccountTransactionsWidget,
        ContentLoader,
    },
})
export class AccountBalanceWidget extends Base {
    protected elements = {
        bsw: ['interactive-icon', 'center-icon'],
        prokon: ['show-bold-words'],
        'elli*': ['pencil-icon'],
        wdenergy: ['pencil-icon'],
        'wdenergy-us': ['pencil-icon'],
        'dsp-*': ['interactive-icon'],
        'sw-ahrensburg': ['interactive-icon'],
    };

    protected created(): void {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch(
                'payment/accountBalance',
                this.$store.state.contracts.contractId
            );
        }
    }

    get accountBalance(): number | string {
        if (this.accountBalanceLoaded) {
            return this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).accountBalance.balance;
        }
        return 0;
    }

    get accountBalanceLoaded(): boolean {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).accountBalance.balance !== null
        );
    }
}
