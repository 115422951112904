import { ChangeCustomerDataWidget } from '../change-customer-data-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './velbert.html';
import { Emit } from 'vue-property-decorator';
import { ContentLoader } from 'vue-content-loader';

import vSelect from 'vue-select';
import { Watch } from 'vue-property-decorator';

@WithRender
@Component({
    components: {
        'v-select': vSelect,
        ContentLoader,
    },
})
export class ChangeCustomerDataWidgetVelbert extends ChangeCustomerDataWidget {
    public zipFailed = false;
    public emailFailed = false;

    protected salutationOptionsVelbert: {
        value: string | null;
        label: string;
    }[] = [
        { value: null, label: 'Ohne' },
        { value: 'Herr', label: 'Herr' },
        { value: 'Frau', label: 'Frau' },
        { value: 'Herrn/Frau', label: 'Herrn/Frau' },
        { value: 'Herren', label: 'Herren' },
        { value: 'Damen', label: 'Damen' },
        { value: 'Wohngemeinschaft', label: 'Wohngemeinschaft' },
        { value: 'Lebensgemeinschaft', label: 'Lebensgemeinschaft' },
        { value: 'Eigentümergemeinschaft', label: 'Eigentümergemeinschaft' },
        { value: 'Erbengemeinschaft', label: 'Erbengemeinschaft' },
    ];

    protected titleOptionsVelbert: {
        value: string | null;
        label: string;
    }[] = [
        { value: null, label: 'Ohne' },
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Dr. med.', label: 'Dr. med.' },
        { value: 'Dr. med. dent.', label: 'Dr. med. dent.' },
        { value: 'Dr. med. habil.', label: 'Dr. med. habil.' },
        { value: 'Dr. med. vet.', label: 'Dr. med. vet.' },
        { value: 'Dr. jur.', label: 'Dr. jur.' },
        { value: 'Dr. phil.', label: 'Dr. phil.' },
        { value: 'Dr. psych.', label: 'Dr. psych.' },
        { value: 'Dr. rer. nat.', label: 'Dr. rer. nat.' },
        { value: 'Dr. rer. oec.', label: 'Dr. rer. oec.' },
        { value: 'Dr. Dr.', label: 'Dr. Dr.' },
        { value: 'Dr. Ing.', label: 'Dr. Ing.' },
        { value: 'Doktores', label: 'Doktores' },
        { value: 'Dipl.-Betriebswirt', label: 'Dipl.-Betriebswirt' },
        { value: 'Dipl.-Ing.', label: 'Dipl.-Ing.' },
        { value: 'Dipl.-Kfm.', label: 'Dipl.-Kfm.' },
        { value: 'Dipl.-Volksw.', label: 'Dipl.-Volksw.' },
        { value: 'Dipl.-Wirt.-Ing.', label: 'Dipl.-Wirt.-Ing.' },
        { value: 'Dipl.-Journalist', label: 'Dipl.-Journalist' },
        { value: 'Dipl.-Med.', label: 'Dipl.-Med.' },
        { value: 'Dipl.-Psych.', label: 'Dipl.-Psych.' },
        { value: 'Dipl.-Ökonom', label: 'Dipl.-Ökonom' },
        { value: 'Dipl.-Sozialpädagoge', label: 'Dipl.-Sozialpädagoge' },
        { value: 'Dipl.-Sozialarbeiter', label: 'Dipl.-Sozialarbeiter' },
        { value: 'Prof.', label: 'Prof.' },
        { value: 'Prof. Dr.', label: 'Prof. Dr.' },
        { value: 'Prof. Dr. Dr.', label: 'Prof. Dr. Dr.' },
        { value: 'Prof. Dr. Ing.', label: 'Prof. Dr. Ing.' },
        { value: 'Prof. Dr. med.', label: 'Prof. Dr. med.' },
        { value: 'Prof. Dr. phil.', label: 'Prof. Dr. phil.' },
        { value: 'Apotheker', label: 'Apotheker' },
        { value: 'Archit. Bda', label: 'Archit. Bda' },
        { value: 'Architekt', label: 'Architekt' },
        { value: 'Assessorin', label: 'Assessorin' },
        { value: 'B.A.', label: 'B.A.' },
        { value: 'Baptist', label: 'Baptist' },
        { value: 'Bau- & Wirtsch.-Ing.', label: 'Bau- & Wirtsch.-Ing.' },
        { value: 'Bau-Ing.', label: 'Bau-Ing.' },
        { value: 'Bau-Ing. Bdb', label: 'Bau-Ing. Bdb' },
        { value: 'Betriebsleiter', label: 'Betriebsleiter' },
        { value: 'Betriebswirt EDV', label: 'Betriebswirt EDV' },
        { value: 'Bürgermeister', label: 'Bürgermeister' },
        { value: 'Chemie-Ing.', label: 'Chemie-Ing.' },
        { value: 'Dipl.', label: 'Dipl.' },
        { value: 'Dipl. rer. pol.', label: 'Dipl. rer. pol.' },
        { value: 'Dipl.-Arch.', label: 'Dipl.-Arch.' },
        { value: 'Dipl.-Arch. Riba', label: 'Dipl.-Arch. Riba' },
        { value: 'Dipl.-Bauing.', label: 'Dipl.-Bauing.' },
        { value: 'Dipl.-BBW ADG', label: 'Dipl.-BBW ADG' },
        { value: 'Dipl.-Berging.', label: 'Dipl.-Berging.' },
        { value: 'Dipl.-Bibl.', label: 'Dipl.-Bibl.' },
        { value: 'Dipl.-Bio.', label: 'Dipl.-Bio.' },
        { value: 'Dipl.-Biol.', label: 'Dipl.-Biol.' },
        { value: 'Dipl.-Chem.', label: 'Dipl.-Chem.' },
        { value: 'Dipl.-Design.', label: 'Dipl.-Design.' },
        { value: 'Dipl.-Dolm.', label: 'Dipl.-Dolm.' },
        { value: 'Dipl.-Doz.', label: 'Dipl.-Doz.' },
        { value: 'Dipl.-Finanzw.', label: 'Dipl.-Finanzw.' },
        { value: 'Dipl.-Geol.', label: 'Dipl.-Geol.' },
        { value: 'Dipl.-Ger.', label: 'Dipl.-Ger.' },
        { value: 'Dipl.-Hdl.', label: 'Dipl.-Hdl.' },
        { value: 'Dipl.-Ibl.', label: 'Dipl.-Ibl.' },
        { value: 'Dipl.-Inf.', label: 'Dipl.-Inf.' },
        { value: 'Dipl.-Inf. (FH)', label: 'Dipl.-Inf. (FH)' },
        { value: 'Dipl.-Ing. (FH)', label: 'Dipl.-Ing. (FH)' },
        { value: 'Dipl.-Ing. (TH)', label: 'Dipl.-Ing. (TH)' },
        { value: 'Dipl.-Ing. Agr.', label: 'Dipl.-Ing. Agr.' },
        { value: 'Dipl.-Ing. Archit.', label: 'Dipl.-Ing. Archit.' },
        { value: 'Dipl.-Ing. Bauw.', label: 'Dipl.-Ing. Bauw.' },
        { value: 'Dipl.-Ing./Ewe', label: 'Dipl.-Ing./Ewe' },
        { value: 'Dipl.-Ing.-Ing.', label: 'Dipl.-Ing.-Ing.' },
        { value: 'Dipl.-Kff.', label: 'Dipl.-Kff.' },
        { value: 'Dipl.-Kfm. Hdl.', label: 'Dipl.-Kfm. Hdl.' },
        { value: 'Dipl.-Kosmetikerin', label: 'Dipl.-Kosmetikerin' },
        { value: 'Dipl.-Math.', label: 'Dipl.-Math.' },
        { value: 'Dipl.-Oec. troph.', label: 'Dipl.-Oec. troph.' },
        { value: 'Dipl.-Päd.', label: 'Dipl.-Päd.' },
        { value: 'Dipl.-Pflegewirt (FH', label: 'Dipl.-Pflegewirt (FH' },
        { value: 'Dipl.-Physiker', label: 'Dipl.-Physiker' },
        { value: 'Dipl.-Pol.', label: 'Dipl.-Pol.' },
        { value: 'Dipl.-Psych. Dr.', label: 'Dipl.-Psych. Dr.' },
        { value: 'Dipl.-Rpfl.', label: 'Dipl.-Rpfl.' },
        { value: 'Dipl.-Soz.', label: 'Dipl.-Soz.' },
        { value: 'Dipl.-Soz. Ök.', label: 'Dipl.-Soz. Ök.' },
        { value: 'Dipl.-Soz. Päd. (FH)', label: 'Dipl.-Soz. Päd. (FH)' },
        { value: 'Dipl.-Sportlehrer', label: 'Dipl.-Sportlehrer' },
        { value: 'Dipl.-Tanzpäd.', label: 'Dipl.-Tanzpäd.' },
        { value: 'Dipl.-Vermess. Ing.', label: 'Dipl.-Vermess. Ing.' },
        { value: 'Dipl.-Verww.', label: 'Dipl.-Verww.' },
        { value: 'Dipl.-Verww. (FH)', label: 'Dipl.-Verww. (FH)' },
        { value: 'Dipl.-Wirt.-Ing. (FH', label: 'Dipl.-Wirt.-Ing. (FH' },
        { value: 'Dipl.-Wirt.-Sozi.', label: 'Dipl.-Wirt.-Sozi.' },
        { value: 'Dr. Dipl.-Chem.', label: 'Dr. Dipl.-Chem.' },
        { value: 'Dr. Dipl.-Ing.', label: 'Dr. Dipl.-Ing.' },
        { value: 'Dr. Dipl.-Kfm.', label: 'Dr. Dipl.-Kfm.' },
        { value: 'Dr. Dipl.-Päd.', label: 'Dr. Dipl.-Päd.' },
        { value: 'Dr. h.c. Dipl.', label: 'Dr. h.c. Dipl.' },
        { value: 'Dr. Ing. h. c.', label: 'Dr. Ing. h. c.' },
        { value: 'Dr. Iur.', label: 'Dr. Iur.' },
        { value: 'Dr. jur. Dr. phil.', label: 'Dr. jur. Dr. phil.' },
        { value: 'Dr. med. Dipl.-Chem.', label: 'Dr. med. Dipl.-Chem.' },
        { value: 'Dr. rer. pol.', label: 'Dr. rer. pol.' },
        { value: 'Dr. vet.', label: 'Dr. vet.' },
        { value: 'Dr.-Ing. E.H.', label: 'Dr.-Ing. E.H.' },
        { value: 'Drs.', label: 'Drs.' },
        { value: 'Drs. med.', label: 'Drs. med.' },
        { value: 'E-Ing.', label: 'E-Ing.' },
        { value: 'Facharzt', label: 'Facharzt' },
        { value: 'Faching.', label: 'Faching.' },
        { value: 'Geschäftsführer', label: 'Geschäftsführer' },
        { value: 'Gtb-Ing. (FH)', label: 'Gtb-Ing. (FH)' },
        { value: 'Immobilienfachwirt', label: 'Immobilienfachwirt' },
        { value: 'Ing.', label: 'Ing.' },
        { value: 'Ing. Agr.', label: 'Ing. Agr.' },
        { value: 'Ing. grad.', label: 'Ing. grad.' },
        { value: 'Ing. i.R.', label: 'Ing. i.R.' },
        { value: 'Ing. Vdi', label: 'Ing. Vdi' },
        { value: 'M. A.', label: 'M. A.' },
        { value: 'MBA', label: 'MBA' },
        { value: 'med.', label: 'med.' },
        { value: 'Ministerialrat', label: 'Ministerialrat' },
        { value: 'Notar', label: 'Notar' },
        { value: 'Oberamtsrat', label: 'Oberamtsrat' },
        { value: 'Ober-Ing.', label: 'Ober-Ing.' },
        { value: 'Oberkreisdirektor', label: 'Oberkreisdirektor' },
        { value: 'Oberstudiendirektor', label: 'Oberstudiendirektor' },
        { value: 'Oberverwaltungsrat', label: 'Oberverwaltungsrat' },
        { value: 'Ord. Prof. Dr.', label: 'Ord. Prof. Dr.' },
        { value: 'Pastor', label: 'Pastor' },
        { value: 'Pater', label: 'Pater' },
        { value: 'Pfarrer', label: 'Pfarrer' },
        { value: 'Pfarrer Dr.', label: 'Pfarrer Dr.' },
        { value: 'Pfarrerin', label: 'Pfarrerin' },
        { value: 'Ph.D.', label: 'Ph.D.' },
        { value: 'Priv.-Doz. Dr.', label: 'Priv.-Doz. Dr.' },
        { value: 'Prof. Dipl.-Ing.', label: 'Prof. Dipl.-Ing.' },
        { value: 'Prof. Dipl.-Päd.', label: 'Prof. Dipl.-Päd.' },
        { value: 'Prof. F. Jr.', label: 'Prof. F. Jr.' },
        { value: 'Prof. h. c. Dr.', label: 'Prof. h. c. Dr.' },
        { value: 'Prof. hc (BG)', label: 'Prof. hc (BG)' },
        { value: 'Prokurist', label: 'Prokurist' },
        { value: 'Rechtsanwalt', label: 'Rechtsanwalt' },
        { value: 'Rechtsanwälte', label: 'Rechtsanwälte' },
        { value: 'Refa-Ing.', label: 'Refa-Ing.' },
        { value: 'Regierungsvizepräs.', label: 'Regierungsvizepräs.' },
        { value: 'Rektorin', label: 'Rektorin' },
        { value: 'Senator', label: 'Senator' },
        { value: 'Steuerberater', label: 'Steuerberater' },
        { value: 'Steuerberaterin', label: 'Steuerberaterin' },
        { value: 'Stud. Jur.', label: 'Stud. Jur.' },
        { value: 'Studiendirektor', label: 'Studiendirektor' },
        { value: 'Studiendirektor a.D.', label: 'Studiendirektor a.D.' },
        { value: 'Univ.-Prof.', label: 'Univ.-Prof.' },
        { value: 'Univ.-Prof. Dr.', label: 'Univ.-Prof. Dr.' },
        { value: 'Univ.-Prof. Dr. Dr.', label: 'Univ.-Prof. Dr. Dr.' },
        { value: 'Verbandsdirektor i.R', label: 'Verbandsdirektor i.R' },
        { value: 'Vermessungsamtsrat', label: 'Vermessungsamtsrat' },
        { value: 'Verwaltungsdirektor', label: 'Verwaltungsdirektor' },
        { value: 'Verwaltungsleiter', label: 'Verwaltungsleiter' },
        { value: 'Dipl.-Betriebswirtin', label: 'Dipl.-Betriebswirtin' },
        { value: 'Dipl.-Journalistin', label: 'Dipl.-Journalistin' },
        { value: 'Dipl.-Ökonomin', label: 'Dipl.-Ökonomin' },
        { value: 'Dipl.-Sozialpädagogi', label: 'Dipl.-Sozialpädagogi' },
        { value: 'Dipl.-Sozalarbeiteri', label: 'Dipl.-Sozalarbeiteri' },
        { value: 'Apothekerin', label: 'Apothekerin' },
        { value: 'Architektin', label: 'Architektin' },
        { value: 'Assessor', label: 'Assessor' },
        { value: 'Baptistin', label: 'Baptistin' },
        { value: 'Betriebsleiterin', label: 'Betriebsleiterin' },
        { value: 'Betriebswirtin EDV', label: 'Betriebswirtin EDV' },
        { value: 'Bürgermeisterin', label: 'Bürgermeisterin' },
        { value: 'Dipl.-Pflegewirtin', label: 'Dipl.-Pflegewirtin' },
        { value: 'Dipl.-Physikerin', label: 'Dipl.-Physikerin' },
        { value: 'Dipl.-Sportlehrerin', label: 'Dipl.-Sportlehrerin' },
        { value: 'Dr. Dipl.-Kff', label: 'Dr. Dipl.-Kff' },
        { value: 'Fachärztin', label: 'Fachärztin' },
        { value: 'Geschäftsführerin', label: 'Geschäftsführerin' },
        { value: 'Immobilienfachwirtin', label: 'Immobilienfachwirtin' },
        { value: 'Ministerialrätin', label: 'Ministerialrätin' },
        { value: 'Notarin', label: 'Notarin' },
        { value: 'Oberamtsrätin', label: 'Oberamtsrätin' },
        { value: 'Oberkreisdirektorin', label: 'Oberkreisdirektorin' },
        { value: 'Oberstudiendirektori', label: 'Oberstudiendirektori' },
        { value: 'Oberverwaltungsrätin', label: 'Oberverwaltungsrätin' },
        { value: 'Pastorin', label: 'Pastorin' },
        { value: 'Prokuristin', label: 'Prokuristin' },
        { value: 'Rechtsanwältin', label: 'Rechtsanwältin' },
        { value: 'Rechtsanwältinnen', label: 'Rechtsanwältinnen' },
        { value: 'Senatorin', label: 'Senatorin' },
        { value: 'Studiendirektorin', label: 'Studiendirektorin' },
        { value: 'Studiendirektorin a.', label: 'Studiendirektorin a.' },
        { value: 'Verbandsdirektorin i', label: 'Verbandsdirektorin i' },
        { value: 'Vermessungsamtsrätin', label: 'Vermessungsamtsrätin' },
        { value: 'Verwaltungsdirektori', label: 'Verwaltungsdirektori' },
        { value: 'Verwaltungsleiterin', label: 'Verwaltungsleiterin' },
    ];

    private tabs = [
        'personalData',
        'addressData',
        'phoneEmailData',
        'communicationData',
    ];

    private activeTab = '';

    get isShowPostboxField() {
        return (
            this.isPostboxEnabled &&
            (this.displayElement('postbox-checkbox-enabled')
                ? this.showPostboxField
                : true) &&
            this.activeTab === this.tabs[1]
        );
    }

    private formatEmailAddress() {
        if (this.details.emailPrivate) {
            this.details.emailPrivate = this.details.emailPrivate.toLowerCase();
        }
    }

    get salutationCurrentlySelectedVelbert() {
        return this.salutationOptionsVelbert.find(
            (option) => option.value === this.details.salutation
        );
    }

    get titleCurrentlySelectedVelbert() {
        return this.titleOptionsVelbert.find(
            (option) => option.value === this.details.title
        );
    }

    get isSME() {
        if (this.contractsLoaded) {
            const state = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.list[0].contractId
            );
            return state && state.contract.business === true;
        }
        return false;
    }

    get submitButtonDisabled(): boolean {
        if (!this.details.zip || !this.details.city || this.submitPending) {
            return true;
        }
        if (this.activeTab === this.tabs[1]) {
            if (this.isShowPostboxField) {
                return !this.details.postbox;
            } else {
                return !this.details.street || !this.details.houseNumber;
            }
        } else {
            return false;
        }
    }

    get contractsLoaded() {
        return (
            this.$store.state.contracts.list &&
            this.$store.state.contracts.list.length > 0
        );
    }

    public created() {
        this.activeTab = this.tabs[0];
    }

    @Watch('contractsLoaded')
    public contractsLoadedChanged() {
        if (this.contractsLoaded) {
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.list[0].contractId
            );
        }
    }

    private validateEmail() {
        const regex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
        if (regex.test(this.details.emailPrivate)) {
            return true;
        } else {
            return false;
        }
    }

    public submitVelbert() {
        this.formatEmailAddress();
        if (this.details.emailPrivate.length > 50) {
            this.errorInformation.push({
                key: 'customerData.emailPrivate.tooLong',
                message:
                    'Ihre Email Adresse darf nicht länger als 50 Zeichen sein.',
            });
            return;
        }
        this.emailFailed = !this.validateEmail();
        if (this.emailFailed) {
            return;
        }
        this.errorInformation = [];
        this.successInformation = [];
        this.zipFailed = false;
        if (this.activeTab === this.tabs[1]) {
            if (this.isShowPostboxField) {
                this.details.street = '';
                this.details.houseNumber = '';
            } else {
                this.details.postbox = '';
            }
        }
        const regexp = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
        if (this.details.zip.length !== 5 || !regexp.test(this.details.zip)) {
            this.zipFailed = true;
            return;
        }
        this.submit();
    }

    public titleSelectedVelbert(object) {
        if (object && object.value) {
            this.details.title = object.value;
        } else {
            this.details.title = null;
        }
    }

    public salutationSelectedVelbert(object) {
        if (object && object.value) {
            this.details.salutation = object.value;
        } else {
            this.details.salutation = null;
        }
    }

    @Watch('activeTab')
    private activeTabChanged(tab) {
        this.details.activeTab = tab;
    }

    @Emit('delete-toggle-modal')
    private toggleOpenDeleteAccountModal() {
        return;
    }
}
