import { LeftAside } from '../left-aside';
import { default as Component } from 'vue-class-component';
import WithRender from './velbert.html';

import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        date: formatProvider.date(),
    },
})
export class VelbertLeftAside extends LeftAside {
    private tooltipShownFor: any[] = []; // fix for tooltip on first appearance

    get currentRouteName(): string | null | undefined {
        return this.$route.name;
    }

    get contractsLoaded() {
        return (
            this.$store.state.contracts.list &&
            this.$store.state.contracts.list.length > 0
        );
    }

    get isSME() {
        if (this.contractsLoaded) {
            const state = this.$store.getters['tariff/getState'](
                this.$store.state.contracts.list[0].contractId
            );
            return state && state.contract.business === true;
        }
        return false;
    }

    // fix for tooltip on first appearance
    private iconHover(event) {
        this.tooltipShownFor.push(event.target.dataset.id);
    }
}
