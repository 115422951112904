import { Dashboard as DashboardDefault } from '../dashboard';
import { default as Component } from 'vue-class-component';

// Call Render template.
import WithRender from './prokon.html';

// Template
import { Header } from '../../../template/header';
import { Footer } from '../../../template/footer';
import { ProkonLeftSide as LeftAside } from '../../../template/left-aside/custom/prokon';
import { ScrollTop } from '../../../template/scroll-top';

// Widgets
import { AccountBalanceWidget } from '../../../widgets/account-balance-widget';
import { AccountTransactionsWidget } from '../../../widgets/account-transactions-widget';
import { ChangeBillingDataWidget } from '../../../widgets/change-billing-data-widget';
import { ChangeCustomerDataWidget } from '../../../widgets/change-customer-data-widget';
import { ChangePaymentDataWidget } from '../../../widgets/change-payment-data-widget';
import { ChangeWidget } from '../../../widgets/change-widget';
import { ContentLoader } from 'vue-content-loader';
import { CustomerDataCorrectionWidget } from '../../../widgets/customer-data-correction-widget';
import { DepositChangeWidget } from '@/components/widgets/deposit-change-widget';
import { DepositWidget } from '../../../widgets/deposit-widget';
import { InvoiceWidget } from '../../../widgets/invoice-widget';
import { MeterHistoryWidget } from '../../../widgets/meter-history-widget';
import { MeterSubmitWidget } from '../../../widgets/meter-submit-widget';
import { MeterWidget } from '../../../widgets/meter-widget';
import { OptInWidget } from '../../../widgets/opt-in-widget';
import { PostBoxWidget } from '../../../widgets/post-box-widget';
import { PostBoxCombinedWidget } from '../../../widgets/post-box-combined-widget';
import { TariffAvailableWidget } from '../../../widgets/tariff-available-widget/custom/qcells';
import { TariffDetailsWidget } from '../../../widgets/tariff-details-widget';
import { TariffWidget } from '../../../widgets/tariff-widget';
import { StatusBarWidget } from '../../../widgets/status-bar-widget';
import { WelcomeWidget } from '../../../widgets/welcome-widget';
import { CustomerServiceFormWidget } from '../../../widgets/customer-service-form-widget';
import { CustomerServiceWidget } from '../../../widgets/customer-service-widget';
import { SalesWidget } from '../../../widgets/sales-widget';
import { CancellationWidget } from '../../../widgets/cancellation-widget';
import { MoveWidget } from '../../../widgets/move-widget';
import { TariffChangeWidget } from '../../../widgets/tariff-change-widget';
import { TariffOverviewWidget } from '../../../widgets/tariff-overview-widget';
import { DepositOverviewWidget } from '@/components/widgets/deposit-overview-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { AdsPopupWidget } from '@/components/widgets/ads-popup-widget';
import { ModalPasswordUpdateWidget } from '../../../widgets/modal-password-update-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { RevokeWidget } from '@/components/widgets/revoke-widget';
import { customParseInt } from '@/customParseInt';

@WithRender
@Component({
    components: {
        // Template
        'app-header': Header,
        'app-footer': Footer,
        'left-aside': LeftAside,
        'scroll-top': ScrollTop,
        // External packages
        // Widgets
        'account-balance-widget': AccountBalanceWidget,
        'account-transactions-widget': AccountTransactionsWidget,
        'change-billing-data-widget': ChangeBillingDataWidget,
        'change-customer-data-widget': ChangeCustomerDataWidget,
        'change-payment-data-widget': ChangePaymentDataWidget,
        'change-widget': ChangeWidget,
        'content-loader': ContentLoader,
        'customer-data-correction-widget': CustomerDataCorrectionWidget,
        'deposit-change-widget': DepositChangeWidget,
        'deposit-overview-widget': DepositOverviewWidget,
        'deposit-widget': DepositWidget,
        'invoice-widget': InvoiceWidget,
        'opt-in-widget': OptInWidget,
        'meter-history-widget': MeterHistoryWidget,
        'meter-submit-widget': MeterSubmitWidget,
        'meter-widget': MeterWidget,
        'post-box-widget': PostBoxWidget,
        'post-box-combined-widget': PostBoxCombinedWidget,
        'tariff-details-widget': TariffDetailsWidget,
        'tariff-widget': TariffWidget,
        'welcome-widget': WelcomeWidget,
        'status-bar-widget': StatusBarWidget,
        'customer-service-form-widget': CustomerServiceFormWidget,
        'customer-service-widget': CustomerServiceWidget,
        'sales-widget': SalesWidget,
        'cancellation-widget': CancellationWidget,
        'move-widget': MoveWidget,
        'tariff-available-widget': TariffAvailableWidget,
        'tariff-change-widget': TariffChangeWidget,
        'tariff-overview-widget': TariffOverviewWidget,
        'cookie-banner-widget': CookieBannerWidget,
        'ads-popup-widget': AdsPopupWidget,
        'modal-password-update-widget': ModalPasswordUpdateWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        'revoke-widget': RevokeWidget,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class Dashboard extends DashboardDefault {
    get showPostBoxCombinedWidget(): boolean {
        return customParseInt(this.$route.params.id, 10) > 0;
    }

    get showTariffChangeWidget(): boolean {
        return (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length > 0 &&
            this.$store.state.settings.tariffChange &&
            this.contractStatus.statusCode < 6000
        );
    }
}
