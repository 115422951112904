import { ChangePaymentDataWidget } from '../change-payment-data-widget';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import * as Sentry from '@sentry/browser';
import WithRender from './velbert.html';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Auth from '@/interfaces/Auth';
import Payment from '@/interfaces/Payment';
import { ContentLoader } from 'vue-content-loader';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const paymentProvider = IocContainer.get<Payment>(SERVICES.PAYMENT);
@WithRender
@Component({
    components: {
        ContentLoader,
    },
})
export class ChangePaymentDataWidgetVelbert extends ChangePaymentDataWidget {
    protected sepaDeactivate = false;
    protected fullIbanRequired = false;
    protected newIbanMatchesOld = true;
    protected hideModalDeleteIban = true;

    get sepaMandateCheckboxText() {
        if (this.dataChanged || this.paymentDetails.directDebit === false) {
            return this.$t('widget.cpd.sepa.activate').toLocaleString();
        }

        return this.$t('widget.cpd.sepa').toLocaleString();
    }
    public checkboxOptions(): {
        value: boolean;
        label: string;
        labelAsHtml?: boolean;
    }[] {
        return [
            {
                value: true,
                label: this.sepaMandateCheckboxText,
            },
        ];
    }
    public checkboxOptionsDeactivate(): { value: boolean; label: string }[] {
        return [
            {
                value: true,
                label: this.$t('widget.cpd.sepa.deactivate').toLocaleString(),
            },
        ];
    }

    get submitDisabled() {
        return (
            this.submitPending ||
            (!this.dataChanged && !this.sepaDeactivate) ||
            !this.details.iban ||
            !this.details.bank ||
            this.fullIbanRequired ||
            this.ibanInvalid
        );
    }

    get isTariffDetailsLoading() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).onLoad;
    }

    get isContractDetailsError() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contractDetailsError;
    }

    get isContractDetailsLoading() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contractDetailsLoading;
    }

    get dataChanged() {
        return (
            this.paymentDetails !== null &&
            this.sepaDeactivate === false &&
            (this.details.directDebit !== this.paymentDetails.directDebit ||
                this.details.iban !== this.paymentDetails.iban ||
                this.details.bank !== this.paymentDetails.bank)
        );
    }

    get ibanInvalid() {
        if (
            this.details.iban !== '' &&
            this.details.iban !== null &&
            this.loadingBankPending === false &&
            this.details.bank === '' &&
            !this.fullIbanRequired
        ) {
            return true;
        }
        return false;
    }

    get bank() {
        if (
            this.details.iban &&
            this.details.iban.length >= this.minIBANLength &&
            this.details.iban !== this.paymentDetails.iban &&
            (!this.sepaDeactivate ||
                this.details.bank === null ||
                this.details.bank === '')
        ) {
            this.loadingBankPending = true;
            if (authProvider.isAuthenticated()) {
                if (this.ibanInvalid) {
                    setTimeout(() => {
                        paymentProvider.bankFromIban(this.details.iban).then(
                            (response) => {
                                this.loadingBankPending = false;
                                if (response.data.success) {
                                    this.details.bank =
                                        response.data.data.bankName;
                                } else {
                                    this.details.bank = '';
                                }
                            },
                            (error) => {
                                this.loadingBankPending = false;
                                Sentry.captureException(new Error(error));
                                this.details.bank = '';
                            }
                        );
                    }, 2000);
                }
            }
        }
        if (
            this.paymentDetails !== null &&
            !(
                (this.details.iban === this.paymentDetails.iban ||
                    this.dataChanged ||
                    this.sepaDeactivate) &&
                this.details.iban &&
                this.details.iban.length >= this.minIBANLength
            )
        ) {
            this.details.bank = '';
        }
        return this.details.bank;
    }

    get bicBankPlaceholder() {
        if (!this.loadingBankPending) {
            return this.$t('widget.cpd.bicBankPlaceholder');
        } else {
            return this.$t('widget.cpd.bicBankPlaceholder.waiting');
        }
    }

    @Watch('paymentDetails.activeFrom')
    protected paymentDetailsActiveFromChanged() {
        if (this.allowActiveFrom) {
            this.details.activeFrom = this.paymentDetails.activeFrom
                ? this.paymentDetails.activeFrom
                : null;
        }
    }

    @Watch('details.iban')
    protected detailsIbanChanged() {
        if (
            this.details.iban !== this.paymentDetails.iban &&
            !this.sepaDeactivate
        ) {
            this.details.directDebit = false;
        }
    }

    @Watch('sepaDeactivate')
    @Watch('details.directDebit')
    @Watch('details.bank')
    @Watch('details.iban')
    @Watch('loadingBankPending')
    protected fullIbanRequiredFieldsChanged() {
        if (
            (this.sepaDeactivate ||
                this.details.directDebit !== this.paymentDetails.directDebit) &&
            ((this.details.iban !== null && this.details.iban.includes('*')) ||
                (this.details.iban !== null &&
                    this.paymentDetails.iban !== null &&
                    !this.loadingBankPending &&
                    this.paymentDetails.iban.substring(
                        this.paymentDetails.iban.length - 4
                    ) !==
                        this.details.iban.substring(
                            this.details.iban.length - 4
                        )))
        ) {
            this.fullIbanRequired = true;
        } else {
            this.fullIbanRequired = false;
        }
    }

    @Watch('paymentDetails.directDebit')
    @Watch('paymentDetails.iban')
    @Watch('paymentDetails.bank')
    protected paymentDetailsChanges() {
        this.details.iban = this.paymentDetails.iban;
        this.details.bank = this.paymentDetails.bank;
        this.details.directDebit = this.paymentDetails.directDebit;
    }

    protected checkData() {
        if (this.sepaDeactivate) {
            this.details.directDebit = false;
        }

        this.submit().then(() => {
            this.sepaDeactivate = false;
            this.fullIbanRequired = false;
            this.newIbanMatchesOld = true;
            setTimeout(() => {
                this.successInformation = [];
            }, 4000);
        });
    }

    private deletePaymentData() {
        this.details.directDebit = false;
        this.details.iban = null;
        this.details.bic = null;
        this.details.bank = null;
        this.details.alternativeAccountHolder = null;
        if (this.allowActiveFrom) {
            this.details.activeFrom = null;
        }
        this.checkData();
    }
}
