import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './idp-auth-page.html';
import { Watch } from 'vue-property-decorator';

import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';

import SERVICES from '@/container/Services';
import IocContainer from '@/container/IocContainer';

import Auth from '@/interfaces/Auth';
import * as Sentry from '@sentry/browser';
import { handleNavigationFailure } from '@/router/utils';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
        'cookie-banner-widget': CookieBannerWidget,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class IdpAuthPage extends Base {
    public idpLogin = true;
    public idpRegister = false;
    public idpLoginUrl: string | null = null;
    public idpRegisterUrl: string | null = null;
    public idpPasswordUrl: string | null = null;

    protected idToken: string | null = null;
    protected contractNumber: number | null = null;
    protected customerNumber: number | null = null;
    protected secretFieldType = '';
    protected secretFieldLabel = '';
    protected secretField = '';
    protected secretFieldConfirmed = '';

    protected loading = false;
    protected isLoginAndRegisterStepIdp = false;
    protected isTokenStepIdp = false;
    protected isCustomerNumberStepIdp = false;
    protected isSecretFieldStepIdp = false;

    protected idpMessage = '';
    protected errors: any[] = [];

    protected elements = {
        bsw: ['login-footer-links-bsw'],
        ets: ['app-footer', 'header-v2'],
        velbert: ['login-footer-velbert'],
        // wdenergy: ['app-footer-right', 'ask-for-contract-id'],
        keppel: ['app-login-subtitle'],
        qcells: ['cookie-banner'],
    };
    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get locales(): string[] {
        return this.$i18n.availableLocales;
    }

    get loginAsideStyle(): string | false {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            // No Image, but Centered
            case 'qcells':
                return false;

            default:
                // Image, not Centered
                return (
                    'background-image: url(/img/evus/' +
                    this.currentClient +
                    '/login.jpg), url(/img/evus/' +
                    this.currentClient +
                    '/login.png), url(/img/evus/' +
                    this.currentClient +
                    '/login.gif), url(/img/evus/' +
                    this.currentClient +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get loginLogo(): string {
        return (
            'background-image: url(/img/evus/' +
            this.currentClient +
            '/logo.svg), url(/img/evus/' +
            this.currentClient +
            '/logo.jpg), url(/img/evus/' +
            this.currentClient +
            '/logo.png);'
        );
    }

    get showIdpMessage(): string {
        return this.idpMessage;
    }

    public created(): void {
        this.$store.dispatch('settings/idp').then(() => {
            if (this.$store.state.settings.idpActive) {
                this.idpLoginUrl = this.$store.state.settings.idpLoginUrl;
                this.idpRegisterUrl = this.$store.state.settings.idpRegisterUrl;
                this.idpPasswordUrl = this.$store.state.settings.idpPasswordUrl;
            } else {
                this.$router
                    .push('/')
                    .catch((failure) => handleNavigationFailure(failure));
            }
        });
    }

    protected switchAuth(): void {
        this.idpLogin = !this.idpLogin;
        this.idpRegister = !this.idpRegister;
    }

    @Watch('$route.name', { immediate: true, deep: true })
    private checkRoute(routeName) {
        switch (routeName) {
            case 'idp-auth-page':
                this.isLoginAndRegisterStepIdp = true;
                this.isTokenStepIdp = false;
                break;
            case 'auth-idp':
                this.isLoginAndRegisterStepIdp = false;
                this.isTokenStepIdp = true;
                const { id_token } = this.$route.query;
                if (typeof id_token === 'string') {
                    this.idToken = id_token;
                }
                this.checkIdpToken();
                break;
        }
    }

    private changeLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }

    private checkIdpToken() {
        this.loading = true;
        this.idpMessage = this.$t(
            'registration.preparation.loading'
        ).toLocaleString();
        if (this.idToken) {
            this.$store
                .dispatch('auth/idp', {
                    token: this.idToken,
                    customerId: this.customerNumber,
                    contractId: this.contractNumber,
                    secretField: this.secretFieldConfirmed,
                })
                .then((result) => {
                    this.loading = false;
                    if (result.success) {
                        if (
                            typeof result.step !== 'undefined' &&
                            result.step === 'customerNumber'
                        ) {
                            this.isCustomerNumberStepIdp = true;
                        }

                        if (typeof result.authToken !== 'undefined') {
                            this.auth.authenticated(result.authToken);
                            this.$store.dispatch('auth/set', true).then(() => {
                                this.$router
                                    .push('/dashboard')
                                    .catch((failure) =>
                                        handleNavigationFailure(failure)
                                    );
                            });
                        }
                    } else {
                        if (
                            typeof result.messageLocalized === 'object' &&
                            result.messageLocalized.key
                        ) {
                            this.errors.push([
                                this.$t(
                                    result.messageLocalized.key
                                ).toLocaleString(),
                            ]);
                        } else if (
                            typeof result.messageLocalized === 'string'
                        ) {
                            this.errors.push([result.messageLocalized]);
                        }
                    }
                })
                .catch((error) => {
                    if (
                        typeof error.response !== 'undefined' &&
                        typeof error.response.data !== 'undefined'
                    ) {
                        if (
                            typeof error.response.data.messageLocalized ===
                            'object'
                        ) {
                            if (
                                this.$te(
                                    error.response.data.messageLocalized.key
                                )
                            ) {
                                this.idpMessage = this.$t(
                                    error.response.data.messageLocalized.key
                                ).toLocaleString();
                            } else {
                                this.idpMessage =
                                    error.response.data.messageLocalized.message;
                            }
                        } else if (
                            typeof error.response.data.messageLocalized ===
                            'string'
                        ) {
                            this.idpMessage =
                                error.response.data.messageLocalized;
                        }
                    }
                });
        } else {
            this.idpMessage = this.$t('token.empty').toLocaleString();
        }
    }

    private getSecretField() {
        this.errors = [];
        if (this.customerNumber || this.contractNumber) {
            this.auth
                .getSecretField(this.contractNumber, this.customerNumber, null)
                .then(
                    (response) => {
                        if (response.data.success) {
                            this.secretFieldLabel =
                                response.data.data.secretFieldLabel;
                            this.secretFieldType =
                                response.data.data.secretFieldType;
                            this.isCustomerNumberStepIdp = false;
                            this.isSecretFieldStepIdp = true;
                        } else {
                            this.errors.push([
                                this.$t(
                                    'registration.contract.error'
                                ).toLocaleString(),
                            ]);
                        }
                    },
                    (error) => {
                        this.errors.push([
                            this.$t(
                                'registration.contract.error'
                            ).toLocaleString(),
                        ]);
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            this.errors.push([
                this.$t('registration.noinput').toLocaleString(),
            ]);
        }
    }

    private checkSecretField() {
        if (this.secretField) {
            this.errors = [];

            switch (this.secretFieldType) {
                case 'birthday':
                    if (
                        !this.secretField.match(
                            /^(0[1-9]|[12][0-9]|3[01]).(0[1-9]|1[012]).\d{4}$/
                        )
                    ) {
                        this.errors.push([
                            this.$t(
                                'registration.secret.birthdate.error'
                            ).toLocaleString(),
                        ]);
                        return;
                    }
                    break;
                case 'bankaccount':
                    if (!this.secretField.match(/^[a-zA-Z]{2}\d{3,32}$/)) {
                        this.errors.push([
                            this.$t(
                                'registration.secret.iban.error'
                            ).toLocaleString(),
                        ]);
                        return;
                    }
                    break;
                case 'zip':
                    if (!this.secretField.match(/^\d{4,5}$/)) {
                        this.errors.push([
                            this.$t(
                                'registration.secret.zip.error'
                            ).toLocaleString(),
                        ]);
                        return;
                    }
                    break;
            }

            this.auth
                .checkSecretField(
                    this.contractNumber,
                    this.customerNumber,
                    this.secretFieldType,
                    this.secretField
                )
                .then(
                    (response) => {
                        if (response.data.success) {
                            this.secretFieldConfirmed =
                                response.data.data.secretField;
                            this.checkIdpToken();
                        } else {
                            this.errors.push([
                                this.$t(
                                    'registration.secret.error'
                                ).toLocaleString(),
                            ]);
                        }
                    },
                    (error) => {
                        this.errors = Object.values(error.response.data.errors);
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            this.errors.push([
                this.$t('registration.secret.noinput').toLocaleString(),
            ]);
        }
    }
}
