import { ChangeBillingDataWidgetElliGeneral } from '@/components/widgets/change-billing-data-widget/custom/elli-general';
import { ChangeBillingDataWidgetWdenergy } from '@/components/widgets/change-billing-data-widget/custom/wdenergy';
import { ChangeBillingDataWidgetWdenergyUs } from '@/components/widgets/change-billing-data-widget/custom/wdenergy-us';
import { ChangeBillingDataWidgetVelbert } from '@/components/widgets/change-billing-data-widget/custom/velbert';
import { ChangeBillingDataWidget } from '@/components/widgets/change-billing-data-widget/change-billing-data-widget';

let widget;

if (process.env.VUE_APP_ENVIRONMENT?.includes('elli')) {
    widget = ChangeBillingDataWidgetElliGeneral;
} else if (process.env.VUE_APP_ENVIRONMENT === 'velbert') {
    widget = ChangeBillingDataWidgetVelbert;
} else if (process.env.VUE_APP_ENVIRONMENT === 'ele') {
    widget = ChangeBillingDataWidgetVelbert;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy') {
    widget = ChangeBillingDataWidgetWdenergy;
} else if (process.env.VUE_APP_ENVIRONMENT === 'wdenergy-us') {
    widget = ChangeBillingDataWidgetWdenergyUs;
} else {
    widget = ChangeBillingDataWidget;
}
export { widget as ChangeBillingDataWidget };
