import Base from '@/mixins/base';
import WithRender from './forgot-password-widget.html';
import Component from 'vue-class-component';
import { MessagesBlock } from '@/components/snippets/messages';
import IocContainer from '@/container/IocContainer';
import VelbertAuth from '@/interfaces/VelbertAuth';
import SERVICES from '@/container/Services';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;
import VueButtonSpinner from 'vue-button-spinner';

@WithRender
@Component({
    components: {
        'messages-block': MessagesBlock,
        'vue-button-spinner': VueButtonSpinner,
    },
})
export class ForgotPasswordWidget extends Base {
    public isLoading = false;
    private auth = IocContainer.get<VelbertAuth>(SERVICES.AUTH_WAERMEENERGIE);
    private data: { email: string; username: string } = {
        email: '',
        username: '',
    };

    protected elements = {
        // velbert: ['forgot-password-warning'],
        // ele: ['forgot-password-warning'],
    };

    private successInformation: Record<any, any> = [];
    private failInformation: Record<any, any> = [];
    private errorsBag: {
        email?: string | TranslateResult[];
        username?: string | TranslateResult[];
    } = {};

    private submitForgetPasswordForm() {
        if (!this.validateFields()) {
            return;
        }
        this.isLoading = true;
        this.successInformation = [];
        this.failInformation = [];
        this.errorsBag = {};
        this.auth
            .forgotPassword(this.data)
            .then((response) => {
                this.isLoading = false;
                if (this.responseSuccess(response)) {
                    //
                } else {
                    console.error(response);
                }
            })
            .catch((error) => {
                this.isLoading = false;
                console.error(error);
            });
    }

    private validateFields() {
        const email = this.validateEmail;
        const username = this.validateUsername;
        return email && username;
    }

    private responseSuccess(response) {
        if (response.status === 422) {
            this.errorsBag = response.data.errors;
        }
        this.parseMessageLocalized(response.data);
        return response.data.success;
    }

    get validateEmail(): boolean | void {
        const regex =
            /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
        if (regex.test(this.data.email)) {
            return true;
        }
        const errorMessage = !this.data.email.length
            ? this.$t('required')
            : this.$t('email.invalid');
        this.errorsBag = { ...this.errorsBag, email: [errorMessage] };
    }

    get validateUsername(): boolean | void {
        if (this.data.username.length) {
            return true;
        }
        this.errorsBag = { ...this.errorsBag, username: [this.$t('required')] };
    }

    private parseMessageLocalized(data: { success; messageLocalized }) {
        const messageLocalized: Record<any, any> | string | undefined =
            data.messageLocalized;
        const property: string = data.success ? 'success' : 'fail';
        if (typeof messageLocalized === 'object') {
            this[property + 'Information'].push(messageLocalized);
        } else if (typeof messageLocalized === 'string') {
            this[property + 'Information'].push({
                key: '',
                message: messageLocalized,
            });
        }
    }

    private getError(field) {
        if (!this.errorsBag[field]) {
            return;
        }
        return this.errorsBag[field].join(' ');
    }

    get errorUsername() {
        return this.getError('username');
    }
    get errorEmail() {
        return this.getError('email');
    }
}
