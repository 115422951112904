// -----------------------------
//     Reading Data structure
// -----------------------------

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import * as Sentry from '@sentry/browser';

import Auth from '../../interfaces/Auth';
import Reading from '../../interfaces/Reading';
import { customParseInt } from '@/customParseInt';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const readingProvider = IocContainer.get<Reading>(SERVICES.READING);

// Data Structure
const state: {
    contractId: number | string;
    onLoad: boolean;
    next: {
        daysUntil: any;
        date: any;
        state: any;
        reminderDateCustomerReading: any;
        latestBillingDate: any;
    };
    counter: {
        counterNumber: any;
        activeFrom: any;
        activeUntil: any;
        type: any;
        registers: [];
    };
    readings: [];
    submit: {
        minDate: any;
        maxDate: any;
        lastInvoiceDate: any;
        detailsLoaded: boolean;
    };
    history: {
        counters: [];
        readings: [];
    };
    isReadingDetailsLoading: boolean;
    readingDetailsLoaded: boolean;
    readingDetailsError: boolean;
    isReadingNextLoading: boolean;
    readingNextLoaded: boolean;
    readingNextError: boolean;
}[] = [];

// Outside methods for communication with data.
const actions = {
    async init({ commit }, contractId) {
        commit('INIT_READING_STORE', contractId);
    },
    async next({ commit }, id): Promise<void> {
        if (authProvider.isAuthenticated()) {
            commit('SET_READING_NEXT_LOADING', { data: true, contractId: id });
            commit('SET_READING_NEXT_LOADED', { data: false, contractId: id });
            commit('SET_READING_NEXT_ERROR', { data: false, contractId: id });
            await readingProvider
                .next(id)
                .then(
                    (response): void => {
                        if (response.data) {
                            commit('SET_READING_NEXT_ERROR', {
                                data: false,
                                contractId: id,
                            });
                            commit('SET_READING_NEXT', {
                                data: response.data,
                                contractId: id,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_READING_NEXT_ERROR', {
                            data: true,
                            contractId: id,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_READING_NEXT_LOADING', {
                            data: false,
                            contractId: id,
                        });
                        commit('SET_READING_NEXT_LOADED', {
                            data: true,
                            contractId: id,
                        });
                    }, 1000);
                });
        }
    },
    async details({ commit }, id): Promise<void> {
        if (authProvider.isAuthenticated()) {
            commit('SET_READING_DETAILS_LOADING', {
                data: true,
                contractId: id,
            });
            commit('SET_READING_DETAILS_ERROR', {
                data: false,
                contractId: id,
            });
            commit('SET_READING_DETAILS_LOADED', {
                data: false,
                contractId: id,
            });
            await readingProvider
                .details(id)
                .then(
                    (response): void => {
                        if (response.data) {
                            commit('SET_READING_DETAILS_ERROR', {
                                data: false,
                                contractId: id,
                            });
                            commit('SET_READING_DETAILS', {
                                data: response.data,
                                contractId: id,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_READING_DETAILS_ERROR', {
                            data: true,
                            contractId: id,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        commit('SET_READING_DETAILS_LOADING', {
                            data: false,
                            contractId: id,
                        });
                        commit('SET_READING_DETAILS_LOADED', {
                            data: true,
                            contractId: id,
                        });
                    }, 1000);
                });
        }
    },
    async history({ commit }, id): Promise<void> {
        if (authProvider.isAuthenticated()) {
            await readingProvider.history(id).then(
                (response): void => {
                    if (response.data) {
                        commit('SET_READING_HISTORY', {
                            data: response.data,
                            contractId: id,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
};

// Logic that change data
const mutations = {
    INIT_READING_STORE(localState, contract) {
        const contractId = customParseInt(contract, 10);
        const stateAlreadySet = localState.find(
            (readingState) => readingState.contractId === contractId
        );
        if (!stateAlreadySet) {
            localState.push({
                contractId,
                onLoad: true,
                next: {
                    daysUntil: null,
                    date: null,
                    state: null,
                    reminderDateCustomerReading: null,
                    latestBillingDate: null,
                },
                counter: {
                    counterNumber: null,
                    activeFrom: null,
                    activeUntil: null,
                    type: null,
                    registers: [],
                },
                readings: [],
                submit: {
                    minDate: null,
                    maxDate: null,
                    lastInvoiceDate: null,
                    detailsLoaded: false,
                },
                history: {
                    counters: [],
                    readings: [],
                },
                isReadingDetailsLoading: true,
                readingDetailsLoaded: false,
                readingDetailsError: false,
                isReadingNextLoading: true,
                readingNextLoaded: false,
                readingNextError: false,
            });
        }
    },
    SET_READING_NEXT(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;

        const currentReading = localState.find(
            (readingState) => readingState.contractId === contractId
        );

        if (!currentReading) return;

        currentReading.next.daysUntil = data.daysUntil ? data.daysUntil : 0;
        currentReading.next.state = data.state ? data.state : 'turnus';
        currentReading.next.date = data.date ? data.date : null;
        currentReading.next.reminderDateCustomerReading = data.date
            ? data.reminderDateCustomerReading
            : null;
        currentReading.next.latestBillingDate = data.date
            ? data.latestBillingDate
            : null;
    },
    SET_READING_NEXT_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).isReadingNextLoading = data;
    },
    SET_READING_NEXT_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readingNextLoaded = data;
    },
    SET_READING_NEXT_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readingNextError = data;
    },
    SET_READING_DETAILS(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).counter.counterNumber = null;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).counter.activeFrom = null;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).counter.activeUntil = null;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).counter.type = null;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).counter.registers = [];
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readings = [];

        if (data.data) {
            if (data.data.counter) {
                const counter = data.data.counter;
                localState.find(
                    (readingState) => readingState.contractId === contractId
                ).counter.counterNumber = counter.counterNumber || null;
                localState.find(
                    (readingState) => readingState.contractId === contractId
                ).counter.activeFrom = counter.activeFrom || null;
                localState.find(
                    (readingState) => readingState.contractId === contractId
                ).counter.activeUntil = counter.activeUntil || null;
                localState.find(
                    (readingState) => readingState.contractId === contractId
                ).counter.type = counter.type || null;
                localState.find(
                    (readingState) => readingState.contractId === contractId
                ).counter.registers = counter.registers || [];
            }
        }
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readings = data.data.readings ? data.data.readings : [];

        if (data.maxSubmitDate) {
            const max = new Date(data.maxSubmitDate);
            const tzoffset = new Date().getTimezoneOffset() * 60000;
            localState.find(
                (readingState) => readingState.contractId === contractId
            ).submit.maxDate = max.getTime() - tzoffset;
        } else {
            localState.find(
                (readingState) => readingState.contractId === contractId
            ).submit.maxDate = null;
        }

        if (data.minSubmitDate) {
            const min = new Date(data.minSubmitDate);
            const tzoffset = new Date().getTimezoneOffset() * 60000;
            localState.find(
                (readingState) => readingState.contractId === contractId
            ).submit.minDate = min.getTime() - tzoffset;
        } else {
            localState.find(
                (readingState) => readingState.contractId === contractId
            ).submit.minDate = null;
        }

        localState.find(
            (readingState) => readingState.contractId === contractId
        ).submit.lastInvoiceDate = data.lastInvoiceDate || null;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).submit.detailsLoaded = true;
    },
    SET_READING_DETAILS_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readingDetailsLoaded = data;
    },
    SET_READING_DETAILS_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).isReadingDetailsLoading = data;
    },
    SET_READING_DETAILS_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).readingDetailsError = data;
    },
    SET_READING_HISTORY(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).history.counters = data.counters ? data.counters : [];
        localState.find(
            (readingState) => readingState.contractId === contractId
        ).history.readings = data.readings ? data.readings : [];
    },
};

// Get Data transformation (like computed)
const getters = {
    getState: (localState) => (contractId) => {
        return localState.find(
            (readingState) => readingState.contractId === contractId
        );
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
