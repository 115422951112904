import { default as Component } from 'vue-class-component';
import WithRender from './request-username-reset-widget.html';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';

import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import AuthPlus from '@/interfaces/AuthPlus';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class RequestUsernameResetWidget extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    protected email = '';
    protected settings: Partial<AuthPlus> = {};

    protected elements = {
        bsw: ['input-with-label'],
        'dsp-*': ['auth-plus'],
        'sw-ahrensburg': ['auth-plus'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get isAuthPlusActive() {
        return this.settings.isAuthPlusActive;
    }

    public created() {
        this.settings = this.$store.state.settings;
    }

    private setUsernameReset() {
        this.successInformation = [];
        this.errorInformation = [];

        if (this.email) {
            this.auth
                .setUsernameReset(this.email, window.location.href)
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (
                        typeof error.response !== 'undefined' &&
                        typeof error.response.data.messageLocalized === 'object'
                    ) {
                        this.errorInformation.push(
                            error.response.data.messageLocalized
                        );
                    } else if (
                        typeof error.response.data.messageLocalized === 'string'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: error.response.data.messageLocalized,
                        });
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                    }

                    Sentry.captureException(new Error(error));
                });
        } else {
            this.errorInformation.push({
                key: 'username.reset.email.required',
                message: '',
            });
        }
    }
}
