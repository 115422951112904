// -----------------------------
//    Payment Data structure
// -----------------------------

import IocContainer from '../../container/IocContainer';
import SERVICES from '../../container/Services';

import * as Sentry from '@sentry/browser';

import Auth from '../../interfaces/Auth';
import Payment from '../../interfaces/Payment';

import _ from 'lodash';
import { customParseInt } from '@/customParseInt';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const paymentProvider = IocContainer.get<Payment>(SERVICES.PAYMENT);

// Data Structure
const state: {
    contractId: number | string;
    onLoad: boolean;
    isInvoicesLoading: boolean;
    invoicesLoaded: boolean;
    invoicesError: boolean;
    deposit: {
        isDetailsLoading: boolean;
        detailsLoaded: boolean;
        detailsError: boolean;
        current: any;
        minValue: any;
        maxValue: any;
        recommended: any;
        useAt: any;
        useFor: any;
        isLoading: boolean;
        loaded: any;
        error: boolean;
    };
    invoices: any;
    accountBalance: {
        totalDebit: any;
        totalDebitDue: any;
        totalCredit: any;
        balance: any;
        balanceDue: any;
        transactions: {
            receivable: any;
            payment: any;
        };
    };
    depositList: any;
    depositPlan: any;
    isDepositPlanLoading: boolean;
    depositPlanLoaded: boolean;
    depositPlanError: boolean;
    isAccountBalanceLoading: boolean;
    accountBalanceError: boolean;
}[] = [];

// Outside methods for communication with data.
const actions = {
    async init({ commit }, contractId) {
        commit('INIT_PAYMENT_STORE', contractId);
    },
    async depositCurrent({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            commit('SET_PAYMENT_DEPOSIT_LOADING', {
                data: true,
                contractId: data,
            });
            commit('SET_PAYMENT_DEPOSIT_LOADED', {
                data: false,
                contractId: data,
            });
            commit('SET_PAYMENT_DEPOSIT_ERROR', {
                data: false,
                contractId: data,
            });

            await paymentProvider
                .depositCurrent(data)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_PAYMENT_DEPOSIT_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_PAYMENT_DEPOSIT_CURRENT', {
                                data: response.data,
                                contractId: data,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_PAYMENT_DEPOSIT_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_PAYMENT_DEPOSIT_LOADING', {
                        data: false,
                        contractId: data,
                    });
                    commit('SET_PAYMENT_DEPOSIT_LOADED', {
                        data: true,
                        contractId: data,
                    });
                });
        }
    },
    async depositDetails({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            commit('SET_PAYMENT_DEPOSIT_DETAILS_LOADING', {
                data: true,
                contractId: data,
            });
            commit('SET_PAYMENT_DEPOSIT_DETAILS_LOADED', {
                data: false,
                contractId: data,
            });
            commit('SET_PAYMENT_DEPOSIT_DETAILS_ERROR', {
                data: false,
                contractId: data,
            });
            await paymentProvider
                .depositDetails(data)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_PAYMENT_DEPOSIT_DETAILS_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_PAYMENT_DEPOSIT_DETAILS', {
                                data: response.data,
                                contractId: data,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_PAYMENT_DEPOSIT_DETAILS_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_PAYMENT_DEPOSIT_DETAILS_LOADING', {
                        data: false,
                        contractId: data,
                    });
                    commit('SET_PAYMENT_DEPOSIT_DETAILS_LOADED', {
                        data: true,
                        contractId: data,
                    });
                });
        }
    },
    async invoices({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            commit('SET_INVOICES_LOADED', { data: false, contractId: data });
            commit('SET_INVOICES_ERROR', { data: false, contractId: data });
            await paymentProvider
                .invoices(data)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_INVOICES_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_PAYMENT_INVOICES', {
                                data: response.data,
                                contractId: data,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_INVOICES_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_INVOICES_LOADED', {
                        data: true,
                        contractId: data,
                    });
                });
        }
    },
    async accountBalance({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            commit('SET_PAYMENT_ACCOUNT_BALANCE_LOADING', {
                data: true,
                contractId: data,
            });
            commit('SET_PAYMENT_ACCOUNT_BALANCE_ERROR', {
                data: false,
                contractId: data,
            });
            await paymentProvider
                .accountBalance(data)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_PAYMENT_ACCOUNT_BALANCE_ERROR', {
                                data: false,
                                contractId: data,
                            });
                            commit('SET_PAYMENT_ACCOUNT_BALANCE', {
                                data: response.data,
                                contractId: data,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_PAYMENT_ACCOUNT_BALANCE_ERROR', {
                            data: true,
                            contractId: data,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_PAYMENT_ACCOUNT_BALANCE_LOADING', {
                        data: false,
                        contractId: data,
                    });
                });
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async bankFromIban({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await paymentProvider.bankFromIban(data).then(
                (response) => {
                    if (response.data) {
                        // commit('SET_USER', response.data.user);
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async bicFromIban({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await paymentProvider.bicFromIban(data).then(
                (response) => {
                    if (response.data) {
                        // commit('SET_USER', response.data.user);
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async depositList({ commit }, data) {
        if (authProvider.isAuthenticated()) {
            await paymentProvider.depositList(data).then(
                (response) => {
                    if (response.data) {
                        commit('SET_PAYMENT_DEPOSIT_LIST', {
                            data: response.data,
                            contractId: data,
                        });
                    }
                },
                (error) => {
                    Sentry.captureException(new Error(error));
                }
            );
        }
    },
    async depositPlan({ commit }, id) {
        if (authProvider.isAuthenticated()) {
            commit('SET_PAYMENT_DEPOSIT_PLAN_LOADED', {
                data: false,
                contractId: id,
            });
            commit('SET_PAYMENT_DEPOSIT_PLAN_LOADING', {
                data: true,
                contractId: id,
            });
            commit('SET_PAYMENT_DEPOSIT_PLAN_ERROR', {
                data: false,
                contractId: id,
            });
            await paymentProvider
                .depositPlan(id)
                .then(
                    (response) => {
                        if (response.data) {
                            commit('SET_PAYMENT_DEPOSIT_PLAN_ERROR', {
                                data: false,
                                contractId: id,
                            });
                            commit('SET_PAYMENT_DEPOSIT_PLAN', {
                                data: response.data,
                                contractId: id,
                            });
                        }
                    },
                    (error) => {
                        commit('SET_PAYMENT_DEPOSIT_PLAN_ERROR', {
                            data: true,
                            contractId: id,
                        });
                        Sentry.captureException(new Error(error));
                    }
                )
                .finally(() => {
                    commit('SET_PAYMENT_DEPOSIT_PLAN_LOADED', {
                        data: true,
                        contractId: id,
                    });
                    commit('SET_PAYMENT_DEPOSIT_PLAN_LOADING', {
                        data: false,
                        contractId: id,
                    });
                });
        }
    },
};

// Logic that change data
const mutations = {
    INIT_PAYMENT_STORE(localState, contract) {
        const contractId = customParseInt(contract, 10);
        const stateAlreadySet = localState.find(
            (paymentState) => paymentState.contractId === contractId
        );
        if (!stateAlreadySet) {
            localState.push({
                contractId,
                onLoad: true,
                isInvoicesLoading: true,
                invoicesLoaded: false,
                invoicesError: false,
                deposit: {
                    isDetailsLoading: true,
                    detailsLoaded: false,
                    detailsError: false,
                    current: null,
                    minValue: null,
                    maxValue: null,
                    recommended: null,
                    useAt: null,
                    useFor: null,
                    isLoading: true,
                    loaded: false,
                    error: false,
                },
                invoices: [],
                accountBalance: {
                    totalDebit: null,
                    totalDebitDue: null,
                    totalCredit: null,
                    balance: null,
                    balanceDue: null,
                    transactions: {
                        receivable: [],
                        payment: [],
                    },
                },
                depositList: [],
                depositPlan: [],
                isDepositPlanLoading: true,
                depositPlanLoaded: false,
                depositPlanError: false,
                isAccountBalanceLoading: true,
                accountBalanceError: false,
            });
        }
    },
    SET_PAYMENT_DEPOSIT_CURRENT(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.current = data.value ? data.value : null;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.useAt = data.useAt ? data.useAt : null;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.useFor = data.useFor ? data.useFor : null;
    },
    SET_PAYMENT_DEPOSIT_DETAILS(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.minValue = data.minValue ? _.ceil(data.minValue) : null;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.maxValue = data.maxValue ? _.floor(data.maxValue) : null;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.recommended = data.recommended ?? null;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.useAt = data.useAt ? data.useAt : null;
    },
    SET_PAYMENT_DEPOSIT_DETAILS_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.isDetailsLoading = data;
    },
    SET_PAYMENT_DEPOSIT_DETAILS_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.detailsLoaded = data;
    },
    SET_PAYMENT_DEPOSIT_DETAILS_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.detailsError = data;
    },
    SET_PAYMENT_DEPOSIT_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.isLoading = data;
    },
    SET_PAYMENT_DEPOSIT_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.loaded = data;
    },
    SET_PAYMENT_DEPOSIT_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).deposit.error = data;
    },
    SET_INVOICES_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).isInvoicesLoading = data;
    },
    SET_INVOICES_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).invoicesLoaded = data;
    },
    SET_INVOICES_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).invoicesError = data;
    },
    SET_PAYMENT_INVOICES(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).invoices = [];
        if (data && data.length > 0) {
            const invoices: any = [];
            data.forEach((element) => {
                invoices.push({
                    invoiceNumber: element.invoiceNumber || null,
                    periodStart: element.periodStart || null,
                    periodEnd: element.periodEnd || null,
                    sum: element.sum || null,
                    value: element.value || null,
                    unsettledValue: element.unsettledValue || null,
                    isCancelled: element.isCancelled || null,
                    isDownloadAvailable: element.isDownloadAvailable || null,
                    cancellationOf: element.cancellationOf || null,
                    usage: element.usage || null,
                    invoiceType: element.invoiceType || null,
                });
            });
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).invoices = invoices;
        }
    },
    SET_PAYMENT_ACCOUNT_BALANCE(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        if (data.account) {
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalDebit = data.account.totalDebit || 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalDebitDue = data.account.totalDebitDue || 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalCredit = data.account.totalCredit || 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.balance = data.account.balance || 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.balanceDue = data.account.balanceDue || 0;
        } else {
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalDebit = 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalDebitDue = 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.totalCredit = 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.balance = 0;
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.balanceDue = 0;
        }

        if (data.transactions && data.transactions.receivable) {
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.transactions.receivable = [];
            data.transactions.receivable.forEach((element) => {
                localState
                    .find(
                        (paymentState) => paymentState.contractId === contractId
                    )
                    .accountBalance.transactions.receivable.push({
                        bookingNumber: element.bookingNumber
                            ? element.bookingNumber
                            : null,
                        createdAt: element.createdAt ? element.createdAt : null,
                        notice: element.notice ? element.notice : null,
                        payableAt: element.payableAt ? element.payableAt : null,
                        overdue: element.overdue ? element.overdue : null,
                        grossAmount: element.grossAmount
                            ? element.grossAmount
                            : null,
                        fulfilled: element.fulfilled ? element.fulfilled : 0,
                        notFulfilled: element.notFulfilled
                            ? element.notFulfilled
                            : 0,
                        relatedBookingNumbers: element.relatedBookingNumbers
                            ? element.relatedBookingNumbers
                            : [],
                        active: false,
                        type: element.type ? element.type : null,
                    });
            });
        }
        if (data.transactions && data.transactions.payment) {
            localState.find(
                (paymentState) => paymentState.contractId === contractId
            ).accountBalance.transactions.payment = [];
            data.transactions.payment.forEach((element) => {
                localState
                    .find(
                        (paymentState) => paymentState.contractId === contractId
                    )
                    .accountBalance.transactions.payment.push({
                        bookingNumber: element.bookingNumber
                            ? element.bookingNumber
                            : null,
                        createdAt: element.createdAt ? element.createdAt : null,
                        notice: element.notice ? element.notice : null,
                        payableAt: element.payableAt ? element.payableAt : null,
                        overdue: element.overdue ? element.overdue : null,
                        grossAmount: element.grossAmount
                            ? element.grossAmount
                            : null,
                        fulfilled: element.fulfilled ? element.fulfilled : 0,
                        notFulfilled: element.notFulfilled
                            ? element.notFulfilled
                            : 0,
                        relatedBookingNumbers: element.relatedBookingNumbers
                            ? element.relatedBookingNumbers
                            : [],
                        active: false,
                        createdAtDate: element.createdAtDate
                            ? element.createdAtDate
                            : null,
                        type: element.type ? element.type : null,
                    });
            });
        }
    },
    SET_PAYMENT_ACCOUNT_BALANCE_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).isAccountBalanceLoading = data;
    },
    SET_PAYMENT_ACCOUNT_BALANCE_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).accountBalanceError = data;
    },
    SET_PAYMENT_DEPOSIT_LIST(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).depositList = [];

        data.deposits.forEach((element) => {
            if (
                !element.activeUntil ||
                new Date(element.activeUntil) > new Date(element.activeFrom)
            ) {
                localState
                    .find(
                        (paymentState) => paymentState.contractId === contractId
                    )
                    .depositList.push({
                        activeFrom: element.activeFrom || null,
                        activeUntil: element.activeUntil || null,
                        grossAmount: element.grossAmount || null,
                        nextExecution: element.nextExecution || null,
                    });
            }
        });
    },
    SET_PAYMENT_DEPOSIT_PLAN(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).depositPlan = [];

        data.forEach((element) => {
            localState
                .find((paymentState) => paymentState.contractId === contractId)
                .depositPlan.push({
                    date: element.date || null,
                    value: element.value || null,
                });
        });
    },
    SET_PAYMENT_DEPOSIT_PLAN_LOADING(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).isDepositPlanLoading = data;
    },
    SET_PAYMENT_DEPOSIT_PLAN_LOADED(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).depositPlanLoaded = data;
    },
    SET_PAYMENT_DEPOSIT_PLAN_ERROR(localState, dataBag) {
        const data = dataBag.data;
        const contractId = dataBag.contractId;
        localState.find(
            (paymentState) => paymentState.contractId === contractId
        ).depositPlanError = data;
    },
};

// Get Data transformation (like computed)
const getters = {
    getState: (localState) => (contractId) => {
        return localState.find(
            (paymentState) => paymentState.contractId === contractId
        );
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
