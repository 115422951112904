<div class="m-grid m-grid--hor m-grid--root m-error">
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-error m-error-6"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-error__aside"
            :style="pageNotFoundAsideStyle"
            v-if="pageNotFoundAsideStyle"
        ></div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-error__wrapper"
        >
            <div class="m-error__body">
                <div class="error-logo">
                    <div :style="logo"></div>
                </div>
                <div class="m-error__title">
                    <h1>{{ $t('not-found-page.title') }}</h1>
                </div>
                <div class="m-error__number">
                    <span>{{ $t('not-found-page.number')}}</span>
                </div>
                <div class="m-error__number-subtitle">
                    <span>{{ $t('not-found-page.number.subtitle') }}</span>
                </div>
                <div class="m-error__text">
                    <p>{{ $t('not-found-page.text-1') }}</p>
                    <p v-html="$t('not-found-page.text-2')"></p>
                </div>
                <router-link
                    :to="{name: 'login'}"
                    class="btn btn-primary m-btn--custom m-btn--pill"
                    >{{ $t('not-found-page.button') }}</router-link
                >
                <div
                    class="mt-auto row w-100"
                    v-if="displayElement('footer-links-velbert')"
                >
                    <hr class="w-100" />
                    <div
                        class="col-12 col-sm-6 col-lg-plus-7 px-0 d-flex justify-content-between"
                    >
                        <div class="me-2">
                            <a
                                class="d-block text-dark"
                                href="mailto:kundenservice@stwvelbert.de"
                            >
                                kundenservice@stwvelbert.de
                            </a>
                            <a class="d-block text-dark" href="tel:08003433637"
                                >0800 343 3637</a
                            >
                        </div>
                        <div class="me-lg-2">
                            Mo bis Fr: 07:00-20:00 Uhr<br />
                            Sa: 08:00-18:00 Uhr<br />
                            So: 09:00-18:00 Uhr
                        </div>
                    </div>
                    <div
                        class="col-12 col-sm-6 col-lg-plus-5 text-center text-sm-right px-0"
                    >
                        <a
                            class="m-link ms-sm-auto"
                            href="https://www.stadtwerke-velbert.de/serviceportal-agb"
                            target="_blank"
                            >{{ $t('footer.agb') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="https://www.stadtwerke-velbert.de/de/impressum.html"
                            target="_blank"
                            >{{ $t('login.footer.impressum') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="https://www.stadtwerke-velbert.de/de/datenschutz.html"
                            target="_blank"
                            >{{ $t('login.footer.privacy') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="https://www.stadtwerke-velbert.de/de/disclaimer.html"
                            target="_blank"
                            >{{ $t('login.footer.disclaimer') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="#"
                            onClick="UC_UI.showSecondLayer();"
                            >Cookies</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
