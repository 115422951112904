import { RequestPasswordResetWidget } from '../request-password-reset-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './velbert.html';
import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import VelbertAuth from '@/interfaces/VelbertAuth';
import SERVICES from '@/container/Services';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class RequestPasswordResetWidgetWarmeenergie extends RequestPasswordResetWidget {
    public username = '';
    public email = '';

    private password = '';
    private passwordConfirmation = '';

    private tokenValid = false;
    private token: string | null = null;

    private authW = IocContainer.get<VelbertAuth>(SERVICES.AUTH_WAERMEENERGIE);

    public mounted() {
        this.token = this.$route.hash.slice(4);
    }

    public checkToken() {
        this.errorInformation = [];
        const data = {
            email: this.email,
            username: this.username,
            token: this.token,
        };
        this.authW.checkToken(data).then((response) => {
            if (response.data.success) {
                this.tokenValid = true;
            } else {
                if (response.data.errors && response.data.errors.username) {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.errors.username[0],
                    });
                } else if (response.data.errors && response.data.errors.email) {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.errors.email[0],
                    });
                } else {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.messageLocalized,
                    });
                }
            }
        });
    }

    public setPassword() {
        const data = {
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
            token: this.token,
        };

        this.authW.resetPassword(data).then((response) => {
            if (response.data.success) {
                this.successInformation.push({
                    key: '',
                    message: response.data.messageLocalized,
                });
            } else {
                this.errorInformation = [];
                if (response.data.errors && response.data.errors.password) {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.errors.password[0],
                    });
                } else if (
                    response.data.errors &&
                    response.data.errors.passwordConfirmation
                ) {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.errors.passwordConfirmation[0],
                    });
                } else if (
                    response.data.errors &&
                    response.data.errors.username
                ) {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.errors.username[0],
                    });
                } else {
                    this.errorInformation.push({
                        key: '',
                        message: response.data.messageLocalized,
                    });
                }
            }
        });
    }
}
