import { default as Component } from 'vue-class-component';
import WithRender from './password.html';
import Base from '@/mixins/base';
import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
    },
})
export class Password extends Base {
    protected elements = {
        bsw: ['login-footer-links-bsw'],
        ets: ['app-footer', 'header-v2'],
        velbert: ['login-footer-velbert', 'hide-login-title'],
        ele: ['login-footer-ele', 'hide-login-title'],
        wdenergy: ['app-footer-right'],
        'wdenergy-us': ['app-footer-right'],
    };
    private errors: any[] = [];
}
