<div>
    <div
        class="registration-step1-warning m-login__title"
        v-if="displayElement('forgot-username-warning') && isProduction"
    >
        <b>{{ $t('login.step1.warning.title') }}</b>
        <br />
        <br />
        {{ $t('login.step1.warning.text.username') }}
        <br />
        <br />
        <button
            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
            @click="$router.push({name: 'login'})"
        >
            {{ $t('back') }}
        </button>
    </div>
    <div v-else>
        <h2 class="text-center">{{$t('forgot.username.title')}}</h2>
        <!--begin::Form-->
        <div class="m-login__form m-form">
            <div class="form-group m-form__group">
                <input
                    class="form-control m-input"
                    type="text"
                    name="email"
                    v-model="email"
                    :placeholder="$t('email') + '*'"
                />
            </div>

            <messages-block
                :success="successInfo"
                :errors="failInfo"
            ></messages-block>

            <!--begin::Action-->
            <div class="m-login__action">
                <button
                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                    @click="$router.push({name: 'login'})"
                >
                    {{ $t('back') }}
                </button>
                <button
                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                    @click="submitEmail"
                >
                    {{ $t('forgot.username') }}
                </button>
            </div>
            <!--end::Action-->
        </div>
        <!--end::Form-->
    </div>
</div>
