const SERVICES = {
    ADDON: Symbol('AddOn'),
    AUTH: Symbol('Auth'),
    AUTH_WAERMEENERGIE: Symbol('Auth_Velbert'),
    BONUS: Symbol('Bonus'),
    CONTRACTS: Symbol('Contracts'),
    CONTACT: Symbol('Contact'),
    CUSTOMER: Symbol('Customer'),
    READING: Symbol('Reading'),
    PAYMENT: Symbol('Payment'),
    TARIFF: Symbol('Tariff'),
    FORMAT: Symbol('Format'),
    TRANSLATION: Symbol('Translation'),
    SETTINGS: Symbol('Settings'),
    MAP: Symbol('Map'),
    METER: Symbol('Meter'),
    SURVEY: Symbol('Survey'),
    TRACKING: Symbol('Tracking'),
    COMMODITY: Symbol('Commodity'),
    LANGUAGE: Symbol('Language'),
    VERIFY: Symbol('Verify'),
    FILES: Symbol('Files'),
    MAIL: Symbol('Mail'),
};

export default SERVICES;
