<form-wrapper form="formBillingData">
    <div class="mobile-modal__inner" data-test="change-billing-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-billing-data-widget-close-click"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <i
                            class="fa fa-user-edit readingHistory-icon mt-2"
                            v-if="displayElement('edit-icon')"
                        ></i>
                        <span class="box-title static-title"
                            >{{ $t('widget.cbd.title') }}</span
                        >
                        <div
                            id="billingDataChange-content"
                            class="mobile-modal__wrap"
                        >
                            <div id="billingDataChangeForm">
                                <div class="row">
                                    <div class="col form-col">
                                        <form-group
                                            id="change-billing-data-widget__care-of-recipient"
                                            v-if="!isShowPostboxField"
                                        >
                                            <custom-label>
                                                {{ $t('widget.cbd.co') }}
                                            </custom-label>
                                            <custom-input
                                                id="billing_co"
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t('widget.cbd.co')"
                                                v-model="details.billingCareOfRecipient"
                                            />
                                        </form-group>
                                        <template>
                                            <form-group
                                                id="change-billing-data-widget__zip"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.zip') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_zip"
                                                    data-test="change-billing-data-widget-input--zip"
                                                    type="text"
                                                    class="form-control"
                                                    :class="[zipFailed ? 'validation-failed': '']"
                                                    :placeholder="$t('widget.cbd.zip')"
                                                    v-model="details.billingZip"
                                                />
                                                <div
                                                    v-if="this.zipFailed"
                                                    class="alert alert-danger"
                                                >
                                                    {{
                                                    $t('widget.ccd.zip.failed')
                                                    }}
                                                </div>
                                            </form-group>
                                            <form-group
                                                id="change-billing-data-widget__city"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.place') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_city"
                                                    data-test="change-billing-data-widget-input--city"
                                                    type="text"
                                                    class="form-control"
                                                    :class="[zipFailed ? 'validation-failed': '']"
                                                    :placeholder="$t('widget.cbd.place')"
                                                    v-model="details.billingCity"
                                                />
                                            </form-group>
                                            <form-group
                                                v-if="isPostboxEnabled"
                                                id="change-billing-data-widget__postbox"
                                            >
                                                <custom-checkbox
                                                    inputClass="form-check-input"
                                                    v-model="showPostboxField"
                                                    :options="checkboxOptionsPostbox()"
                                                    :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                    :checked="true"
                                                    :labelClass="[
                                                        'form-check-label',
                                                        displayElement('branded-checkbox')
                                                            ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                            : ''
                                                    ]"
                                                />
                                            </form-group>
                                            <form-group
                                                v-if="isShowPostboxField"
                                                id="change-billing-data-widget__customer-postbox"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.postbox')
                                                    }}
                                                </custom-label>
                                                <custom-input
                                                    type="text"
                                                    :class="['form-control', isPostboxError ? 'form-postbox-error' : '']"
                                                    :placeholder="$t('widget.ccd.postbox')"
                                                    v-model="details.postbox"
                                                />
                                            </form-group>

                                            <form-group
                                                id="change-billing-data-widget__street"
                                                v-if="!isShowPostboxField"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.street')
                                                    }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_street"
                                                    data-test="change-billing-data-widget-input--street"
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cbd.street')"
                                                    v-model="details.billingStreet"
                                                />
                                            </form-group>
                                            <form-group
                                                id="change-billing-data-widget__house-number"
                                                v-if="!isShowPostboxField"
                                            >
                                                <custom-label>
                                                    {{ $t('widget.cbd.house') }}
                                                </custom-label>
                                                <custom-input
                                                    id="billing_houseNumber"
                                                    data-test="change-billing-data-widget-input--house-number"
                                                    type="text"
                                                    class="form-control"
                                                    :placeholder="$t('widget.cbd.house')"
                                                    v-model="details.billingHouseNumber"
                                                />
                                            </form-group>
                                        </template>
                                        <form-group
                                            id="change-billing-data-widget__overwrite-customer"
                                            class="form-group col-12"
                                        >
                                            <custom-checkbox
                                                :options="checkboxOptionsAcceptAsCustomer()"
                                                inputClass="form-check-input"
                                                v-model="details.synchronizeWithCustomer"
                                                :wrapperClass="displayElement('branded-checkbox') ? 'custom-check form-check-label' : ''"
                                                :labelClass="[
                                                    'form-check-label',
                                                    displayElement('branded-checkbox')
                                                        ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand'
                                                        : ''
                                                ]"
                                            />
                                        </form-group>
                                    </div>
                                </div>
                                <a
                                    v-if="displayElement('show-personal-info-link')"
                                    :href="$t('widget.infoPersonalDataLink')"
                                    class="personal-info-link info-link-m0"
                                    target="_blank"
                                >
                                    {{ $t('widget.infoPersonalData') }}
                                </a>
                                <div class="row">
                                    <div class="col-sm-8 col-xs-12">
                                        <messages-block
                                            fromTestElement="change-billing-data-widget-messages"
                                            :success="successInformation"
                                            :errors="errorInformation"
                                        ></messages-block>
                                    </div>
                                    <div
                                        v-if="!displayElement('disableDataChange')"
                                        class="col-sm-4 col-xs-12"
                                    >
                                        <div class="text-end">
                                            <button
                                                data-test="change-billing-data-widget-save-click"
                                                class="btn btn-primary"
                                                @click="submitVelbert"
                                                :disabled="submitButtonDisabledVelbert"
                                            >
                                                {{ $t('widget.cbd.submit') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
