<!--<template>-->
<div>
    <div class="registration-step1-message">{{ $t('login.explanation') }}</div>
    <div class="registration-top-text" v-if="currentTab === 1">
        {{$t('register.text-1')}}
        <a href="#" @click.prevent="setCurrentTab(2)"
            >{{$t('register.registration-button')}}</a
        >
        {{$t('register.text-2')}}
    </div>
    <div class="registration-top-text" v-if="currentTab === 2">
        {{$t('register.text-3')}}
        <a href="#" @click.prevent="setCurrentTab(1)"
            >{{$t('register.registration-button')}}</a
        >
        {{$t('register.text-4')}}
    </div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div class="form-group m-form__group" v-if="currentTab === 1">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.firstname"
                :placeholder="$t('register.firstname') + '*'"
                name="firstname"
            />
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorFirstname && currentTab === 1"
            v-html="errorFirstname"
        ></div>
        <div class="form-group m-form__group" v-if="currentTab === 1">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.surname"
                :placeholder="$t('register.surname') + '*'"
                name="surname"
            />
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorSurname && currentTab === 1"
            v-html="errorSurname"
        ></div>

        <div class="form-group m-form__group" v-if="currentTab === 2">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.surname"
                :placeholder="$t('register.namestring1') + '*'"
                name="surname"
            />
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorNameString1 && currentTab === 2"
            v-html="errorNameString1"
        ></div>
        <div class="form-group m-form__group" v-if="currentTab === 2">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.firstname"
                :placeholder="$t('register.namestring2')"
                name="firstname"
            />
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorNameString2 && currentTab === 2"
            v-html="errorNameString2"
        ></div>

        <div class="form-group m-form__group with-tooltip-right">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.customerNo"
                :placeholder="$t('register.customerNo') + '*'"
                name="customerNo"
                pattern="[0-9]+"
            />
            <i
                class="fas fa-info-circle"
                v-tooltip.bottom="$t('register.customerNo.tooltip')"
            ></i>
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorCustomerNo"
            v-html="errorCustomerNo"
        ></div>
        <div class="form-group m-form__group with-tooltip-right">
            <input
                class="form-control m-input"
                type="text"
                v-model.trim="data.authUserName"
                :placeholder="$t('register.authUserName') + '*'"
                name="authUserName"
            />
            <i
                class="fas fa-info-circle"
                v-tooltip.bottom="$t('register.authUserName.tooltip')"
            ></i>
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorAuthUserName"
            v-html="errorAuthUserName"
        ></div>
        <div class="form-group m-form__group with-tooltip-right">
            <input
                class="form-control m-input"
                type="password"
                v-model="data.password"
                :placeholder="$t('register.password') + '*'"
                name="password"
            />
            <i
                class="fas fa-info-circle"
                v-tooltip.bottom="$t('register.password.tooltip')"
            ></i>
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorPassword"
            v-html="errorPassword"
        ></div>
        <div class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="password"
                v-model="data.passwordConfirmation"
                :placeholder="$t('register.passwordConfirmation') + '*'"
                name="passwordConfirmation"
            />
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorPasswordConfirmation"
            v-html="errorPasswordConfirmation"
        ></div>
        <div class="form-group m-form__group with-tooltip-right">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.email"
                v-on:keyup="formatEmailAddress"
                :placeholder="$t('register.email') + '*'"
                name="email"
            />
            <i
                class="fas fa-info-circle"
                v-tooltip.bottom="$t('register.email.tooltip')"
            ></i>
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorEmail"
            v-html="errorEmail"
        ></div>
        <div class="form-group m-form__group with-tooltip-right">
            <input
                class="form-control m-input"
                type="text"
                v-model="data.emailConfirmation"
                v-on:keyup="formatEmailAddress"
                :placeholder="$t('register.emailConfirmation') + '*'"
                name="emailConfirmation"
            />
            <i
                class="fas fa-info-circle"
                v-tooltip.bottom="$t('register.emailConfirm.tooltip')"
            ></i>
        </div>
        <div
            class="invalid-feedback d-block"
            v-if="errorEmailConfirmation"
            v-html="errorEmailConfirmation"
        ></div>

        <!--begin::Agreement-->
        <div class="mt-4 me-3">
            <label class="me-5">
                <input type="checkbox" v-model="data.agreementTerms" />
                <span
                    v-html="setHref($t('register.agreementTerms'), 'AGB', $t('register.agbLink'))"
                ></span>
                <div
                    class="invalid-feedback d-block"
                    v-if="errorAgreement"
                    v-html="errorAgreement"
                ></div>
            </label>
            <label>
                <input type="checkbox" v-model="data.agreementDigitalPost" />
                <span>{{ $t('register.agreementDigitalPost') }}</span>
                <i
                    class="fas fa-info-circle"
                    v-tooltip.top="$t('register.agreementDigitalPost.tooltip')"
                ></i>
            </label>
            <span
                v-html="setHref($t('register.dataPrivacy'), 'hier', $t('register.dataPrivacyLink'))"
            ></span>
        </div>
        <!--end::Agreement-->
        <br />
        <messages-block
            :success="successInformation"
            :errors="failInformation"
        ></messages-block>

        <!--begin::Action-->
        <div class="m-login__action">
            <a @click="$router.push({name: 'login'})" class="m-link">
                <span class="register-under-button"
                    >{{ $t('login.already.have') }}</span
                >
            </a>

            <vue-button-spinner
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': isLoading}"
                :is-loading="isLoading"
                :disabled="isLoading"
                :status="status"
            >
                <span @click="register">{{ $t('register.button') }}</span>
            </vue-button-spinner>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
<!--</template>-->
