import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './page-not-found.html';
import { Footer } from '../../template/footer';

@WithRender
@Component({
    components: {
        'app-footer': Footer,
    },
})
export class PageNotFound extends Base {
    protected elements = {
        velbert: ['footer-links-velbert'],
    };

    get pageNotFoundAsideStyle() {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            default:
                return (
                    'background-image: url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.jpg), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.png), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.gif), url(/img/evus/' +
                    process.env.VUE_APP_ENVIRONMENT +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get logo() {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.svg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/logo.png);'
        );
    }
}
