import { TariffChangeWidget } from '../tariff-change-widget';
import { default as Component } from 'vue-class-component';
import WithRender from './dsp-flex.html';

import VueButtonSpinner from 'vue-button-spinner';
import { Watch } from 'vue-property-decorator';
import { customParseInt } from '@/customParseInt';

@WithRender
@Component({
    components: {
        'vue-button-spinner': VueButtonSpinner,
    },
})
export class TariffChangeWidgetDspFlex extends TariffChangeWidget {
    public showTariff = false;
    public displayDetails = false;

    protected isLoading = false;

    public tariffDetailsToggle: { productCode: string; value: boolean }[] = [];

    public confirmed = false;

    protected status = '';

    get fixedPriceMonths(): string {
        if (
            !this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).tariff.fixedPriceMonths
        ) {
            return '';
        }
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff.fixedPriceMonths;
    }

    get contract(): any {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract;
        }

        return {
            zip: '',
            usage: 0,
        };
    }

    get postalCode(): string {
        return this.contract.deliveryAddress.zip;
    }

    get usage(): string {
        return this.contract.usage;
    }

    protected getDurationType(type: string, amount: number | string): string {
        if (typeof amount === 'string') {
            amount = parseInt(amount);
        }
        if (type) {
            type = type.toUpperCase();
        }
        if (type === 'MONTHS' && amount === 1) {
            return this.$t('widget.tr.month').toString();
        } else if (type === 'MONTHS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.months').toString();
        } else if (type === 'WEEKS' && amount === 1) {
            return this.$t('widget.tr.week').toString();
        } else if (type === 'WEEKS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.weeks').toString();
        } else if (type === 'DAYS' && amount === 1) {
            return this.$t('widget.tr.day').toString();
        } else if (type === 'DAYS' && (amount > 1 || amount === 0)) {
            return this.$t('widget.tr.days').toString();
        } else {
            return type;
        }
    }

    protected toggleDetails(tariff) {
        const state = this.tariffDetailsToggle.findIndex((item) => {
            return item.productCode == tariff.productCode;
        });
        if (state >= 0) {
            this.tariffDetailsToggle[state].value =
                !this.tariffDetailsToggle[state].value;
        } else {
            this.tariffDetailsToggle.push({
                productCode: tariff.productCode,
                value: true,
            });
        }
    }

    protected getDetailsToggle(tariff) {
        const state = this.tariffDetailsToggle.find((item) => {
            return item.productCode == tariff.productCode;
        });
        if (state) {
            return state.value;
        }

        return false;
    }
}
