import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './deposit-widget.html';

import { DepositChangeWidget } from '../deposit-change-widget';

import SERVICES from '../../../container/Services';
import IocContainer from '../../../container/IocContainer';
import Format from '../../../interfaces/Format';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    name: 'DepositWidget',
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        euroSpecial: formatProvider.euroSpecial(),
    },
    props: {
        isActiveWidget: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ContentLoader,
        DepositChangeWidget,
    },
})
export class DepositWidget extends Base {
    protected elements = {
        bsw: ['interactive-icon', 'center-icon'],
        prokon: ['show-bold-words'],
        'elli*': ['pencil-icon'],
        wdenergy: ['pencil-icon'],
        'wdenergy-us': ['pencil-icon'],
        'dsp-*': ['interactive-icon'],
        'sw-ahrensburg': ['interactive-icon'],
    };

    protected created(): void {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch(
                'payment/depositCurrent',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'payment/depositDetails',
                this.$store.state.contracts.contractId
            );
        }
    }

    get depositDetailsLoaded(): any {
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.detailsLoaded;
    }

    get currentDeposit(): any {
        if (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).deposit.current != null
        ) {
            return this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).deposit.current;
        }
        return this.$store.getters['payment/getState'](
            this.$store.state.contracts.contractId
        ).deposit.recommended;
    }
}
