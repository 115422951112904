import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './meter-widget.html';
import moment from 'moment';

import { MeterSubmitWidget } from '../meter-submit-widget';

@WithRender
@Component({
    props: {
        isActiveWidget: {
            type: Boolean,
            default: false,
        },
        showAction: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ContentLoader,
        MeterSubmitWidget,
    },
})
export class MeterWidget extends Base {
    protected elements = {
        bsw: ['interactive-icon', 'center-icon'],
        prokon: ['show-bold-words'],
        'elli*': ['pencil-icon'],
        wdenergy: ['pencil-icon'],
        'wdenergy-us': ['pencil-icon'],
        'dsp-*': ['interactive-icon'],
        'sw-ahrensburg': ['interactive-icon'],
    };

    protected created() {
        if (this.$store.state.contracts.contractId) {
            this.$store.dispatch(
                'reading/next',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'reading/details',
                this.$store.state.contracts.contractId
            );
        }
    }

    get daysUntil() {
        const daysUntil = this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).next.daysUntil;
        if (daysUntil) {
            return daysUntil + 1;
        }
        return null;
    }

    get state() {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).next.state;
    }

    get inRegistrationState() {
        return (
            this.state === 'waiting' &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).contract.startDate === null
        );
    }

    get finalInvoiceCreated() {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).invoices.filter((invoice) => {
                return (
                    moment(invoice.periodEnd).format('YYYY-MM-DD') ===
                    this.$store.getters['tariff/getState'](
                        this.$store.state.contracts.contractId
                    ).contract.endDate
                );
            }).length === 1
        );
    }

    get detailsLoaded() {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).submit.detailsLoaded;
    }
}
