import { LoginPage } from '@/components/pages/auth/login-page';
import WithRender from './velbert.html';
import Component from 'vue-class-component';

import VueButtonSpinner from 'vue-button-spinner';

@WithRender
@Component({
    props: {
        second: {
            type: Number,
            default: 0,
        },
    },
    components: {
        'vue-button-spinner': VueButtonSpinner,
    },
})
export class LoginPageVelbert extends LoginPage {}
