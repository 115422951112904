import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import { ContentLoader } from 'vue-content-loader';
import WithRender from './non-commodity-tariff-details-widget.html';

import SERVICES from '../../../container/Services';
import IocContainer from '../../../container/IocContainer';
import Format from '../../../interfaces/Format';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@WithRender
@Component({
    filters: {
        euroFull: formatProvider.euroFull(),
        euro: formatProvider.euro(),
        date: formatProvider.date(),
        monthYear: formatProvider.monthYear(),
    },
    components: {
        ContentLoader,
    },
})
export class NonCommodityTariffDetailsWidget extends Base {
    protected elements = {
        bsw: [
            'contract-details-label',
            'hands-helping-icon',
            'show-fixedPrice',
        ],
        qcells: ['hide-nextCancellationDate'],
        velbert: ['close-button-hidden'],
        ele: ['close-button-hidden'],
        shell: ['close-button-hidden'],
        sachsenenergie: ['show-nextCancellationDate-extended'],
        wdenergy: ['no-tilde-fullPrice', 'close-button-hidden'],
        'wdenergy-us': ['no-tilde-fullPrice', 'close-button-hidden'],
    };

    protected created() {
        if (
            this.$store.state.contracts.contractId &&
            ['velbert', 'ele', 'sachsenenergie', 'shell', 'wdenergy'].indexOf(
                this.currentClient || ''
            ) > -1
        ) {
            this.$store.dispatch(
                'tariff/overview',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'tariff/details',
                this.$store.state.contracts.contractId
            );
        }
        if (this.tariffOverviewAllowed) {
            this.$store.dispatch(
                'tariff/list',
                this.$store.state.contracts.contractId
            );
        }
        if (this.tariffChangeAllowed) {
            this.$store.dispatch(
                'tariff/forChange',
                this.$store.state.contracts.contractId
            );
        }
    }

    protected mounted() {
        // window._paq.push(['trackEvent', 'Element Einblenden', 'Tarifdetails']);
    }

    protected destroyed() {
        // window._paq.push(['trackEvent', 'Element Ausblenden', 'Tarifdetails']);
    }

    get tariff() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).tariff;
    }

    get contractMoveAllowed() {
        return (
            this.$store.state.settings.contractMove &&
            this.contract.relocationPossible
        );
    }

    get tariffChangeAllowed() {
        return this.$store.state.settings.tariffChange;
    }

    get tariffOverviewAllowed() {
        return this.$store.state.settings.tariffOverview;
    }

    get contract() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }
}
