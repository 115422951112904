import { default as Component } from 'vue-class-component';
import WithRender from './password-reset-form.html';
import { Password } from './password';
import { RequestPasswordResetWidget } from '@/components/widgets/request-password-reset-widget';
import { RequestPasswordResetWidgetWarmeenergie } from '@/components/widgets/request-password-reset-widget/custom/velbert';

@WithRender
@Component({
    components: {
        RequestPasswordResetWidget,
        RequestPasswordResetWidgetWarmeenergie,
    },
})
export class PasswordResetForm extends Password {}
