import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './restore-two-factor-authentication.html';

import * as Sentry from '@sentry/browser';

import { HeaderV2 } from '@/components/template/header-v2';
import { Footer } from '@/components/template/footer';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { MessagesBlock } from '@/components/snippets/messages';
import { ShowMultipleErrorMessages } from '@/components/snippets/show-multiple-error-messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Auth from '@/interfaces/Auth';
import { handleNavigationFailure } from '@/router/utils';
import { customParseInt } from '@/customParseInt';

@WithRender
@Component({
    components: {
        HeaderV2,
        'app-footer': Footer,
        'cookie-banner-widget': CookieBannerWidget,
        // External packages
        'messages-block': MessagesBlock,
        'show-multiple-error-messages': ShowMultipleErrorMessages,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class RestoreTwoFactorAuthentication extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    public errors: any[] = [];

    protected enableLoadingState = false;
    protected image = '';
    protected secret = '';
    protected code = '';

    private details: {
        username: string | null;
        email: string | null;
        code1: string | null;
        code2: string | null;
        code3: string | null;
        code4: string | null;
    } = {
        username: null,
        email: null,
        code1: null,
        code2: null,
        code3: null,
        code4: null,
    };

    protected elements = {
        bsw: ['login-footer-links-bsw'],
        ets: ['app-footer', 'header-v2'],
        velbert: ['login-footer-velbert'],
        ele: ['login-footer-ele'],
        wdenergy: ['app-footer-right', 'ask-for-contract-id'],
        'wdenergy-us': ['app-footer-right', 'ask-for-contract-id'],
        keppel: ['app-login-subtitle'],
        qcells: ['cookie-banner'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);

    get locales() {
        return this.$i18n.availableLocales;
    }

    get loginAsideStyle() {
        switch (process.env.VUE_APP_ENVIRONMENT) {
            // No Image, but Centered
            case 'qcells':
                return false;

            default:
                // Image, not Centered
                return (
                    'background-image: url(/img/evus/' +
                    this.currentClient +
                    '/login.jpg), url(/img/evus/' +
                    this.currentClient +
                    '/login.png), url(/img/evus/' +
                    this.currentClient +
                    '/login.gif), url(/img/evus/' +
                    this.currentClient +
                    '/login.svg); background-size: cover;'
                );
        }
    }

    get loginLogo() {
        return (
            'background-image: url(/img/evus/' +
            this.currentClient +
            '/logo.svg), url(/img/evus/' +
            this.currentClient +
            '/logo.jpg), url(/img/evus/' +
            this.currentClient +
            '/logo.png);'
        );
    }

    private changeLocale(locale) {
        this.$i18n.locale = locale;
        localStorage.setItem('wd-portal-language', this.$i18n.locale);
    }

    private checkRecoveryCodes() {
        this.enableLoadingState = true;
        this.errors = [];
        this.errorInformation = [];
        this.successInformation = [];

        this.auth
            .twoFactorAuthenticationRecovery({
                username: this.details.username || '',
                email: this.details.email || '',
                codes: [
                    this.details.code1,
                    this.details.code2,
                    this.details.code3,
                    this.details.code4,
                ],
            })
            .then((response) => {
                if (response && response.data) {
                    if (response.data.success) {
                        this.image = response.data.image;
                        this.secret = response.data.secret;
                    } else {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                }
                this.enableLoadingState = false;
            })
            .catch((error) => {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    Object.keys(error.response.data.errors).length > 0
                ) {
                    this.errors = Object.values(error.response.data.errors);
                } else {
                    this.errors.push([this.$t('restore.tfa.find.error')]);
                    Sentry.captureException(new Error(error));
                }
                this.enableLoadingState = false;
            });
    }

    private sendCode() {
        this.enableLoadingState = true;
        this.errorInformation = [];
        this.successInformation = [];

        this.auth
            .twoFactorAuthenticationCheckCode({
                username: this.details.username || '',
                code: this.code,
            })
            .then((response) => {
                if (response && response.data) {
                    if (
                        response.data.success &&
                        typeof response.data.authToken !== 'undefined' &&
                        response.data.authToken
                    ) {
                        this.auth.authenticated(response.data.authToken);
                        this.$store.dispatch('auth/set', true);
                        this.$store.dispatch('auth/errorTryReset');

                        if (
                            this.currentClient === 'velbert' ||
                            this.currentClient === 'ele'
                        ) {
                            this.$store
                                .dispatch('contracts/contracts')
                                .then(() => {
                                    this.$store
                                        .dispatch(
                                            'contracts/setContractId',
                                            customParseInt(
                                                this.contractsList[0]
                                                    .contractId,
                                                10
                                            )
                                        )
                                        .then(() => {
                                            this.$router
                                                .push({
                                                    name: 'dashboardId',
                                                    params: {
                                                        id: this
                                                            .contractsList[0]
                                                            .contractId,
                                                    },
                                                })
                                                .catch((failure) =>
                                                    handleNavigationFailure(
                                                        failure
                                                    )
                                                );
                                        });
                                });
                        } else {
                            this.$router
                                .push({ name: 'dashboard' })
                                .catch((failure) =>
                                    handleNavigationFailure(failure)
                                );
                        }
                    } else {
                        this.enableLoadingState = false;
                        this.$store.dispatch('auth/incrementErrorTry');
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                } else {
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                }
            })
            .catch((error) => {
                this.enableLoadingState = false;
                this.$store.dispatch('auth/incrementErrorTry');
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.errors &&
                    Object.keys(error.response.data.errors).length > 0
                ) {
                    this.errors = Object.values(error.response.data.errors);
                } else {
                    this.errors.push([this.$t('restore.tfa.find.error')]);
                    Sentry.captureException(new Error(error));
                }
            });
    }
}
