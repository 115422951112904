export const customParseInt = (string, radix = 10) => {
    if (
        process.env.VUE_APP_ENVIRONMENT === 'velbert' ||
        process.env.VUE_APP_ENVIRONMENT === 'ele'
    ) {
        return string;
    } else {
        return parseInt(string, radix);
    }
};
