import { default as Component } from 'vue-class-component';
import WithRender from './reset-username-widget.html';

import * as Sentry from '@sentry/browser';

import { MessagesBlock } from '@/components/snippets/messages';
import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';
import Base from '@/mixins/base';

@WithRender
@Component({
    components: {
        // External packages
        'messages-block': MessagesBlock,
    },
})
export class ResetUsernameWidget extends Base {
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];
    protected elements = {
        bsw: ['input-with-label'],
        'dsp-*': ['auth-plus-password-strength-meter'],
        'sw-ahrensburg': ['auth-plus-password-strength-meter'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private email = '';
    private username = '';

    private setUsername() {
        this.errorInformation = [];

        if (this.email && this.username) {
            this.auth
                .setUsername(this.$route.params.hash, this.email, this.username)
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (
                        typeof error.response !== 'undefined' &&
                        typeof error.response.data.messageLocalized === 'object'
                    ) {
                        this.errorInformation.push(
                            error.response.data.messageLocalized
                        );
                    } else if (
                        typeof error.response.data.messageLocalized === 'string'
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: error.response.data.messageLocalized,
                        });
                    } else {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t('general.error').toLocaleString(),
                        });
                    }

                    Sentry.captureException(new Error(error));
                });
        } else {
            if (this.email === '') {
                this.errorInformation.push({
                    key: 'username.reset.email.required',
                    message: '',
                });
            }
            if (this.username === '') {
                this.errorInformation.push({
                    key: 'username.reset.username.required',
                    message: '',
                });
            }
        }
    }
}
