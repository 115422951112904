import vue from 'vue';
import VueRouter from 'vue-router';
import Guard from './Guard';
import store from '@/store/Store';

vue.use(VueRouter);

// Page components
import { Dashboard } from '@/components/pages/dashboard';
import { Survey } from '@/components/pages/advizzo/survey';
import { SalesJourney } from '@/components/pages/sales-journey';
import { PageNotFound } from '@/components/pages/page-not-found';
import { Protected } from '@/components/pages/protected';
import { Contact } from '@/components/pages/contact';
import { AuthPage } from '@/components/pages/auth';
import { SuperCheckAuthToken } from '@/components/pages/auth/super-check-auth-token';
import { IdpAuthPage } from '@/components/pages/auth/idp-auth-page';
import { MeterReading } from '@/components/pages/meter-reading';
import {
    Password,
    PasswordResetPage,
    PasswordResetForm,
    PasswordForgotPage,
} from '@/components/pages/auth/password';
import {
    Username,
    UsernameForgot,
    UsernameResetForm,
    UsernameResetPage,
} from '@/components/pages/auth/username';

import { MaintenancePage } from '@/components/pages/maintenance';

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: '/maintenance',
            name: 'maintenance',
            component: MaintenancePage,
            beforeEnter: Guard.guest,
        },
        {
            path: '/admin',
            name: 'admin',
            beforeEnter: Guard.admin,
        },
        {
            path: '/',
            name: 'login',
            component: AuthPage,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Login',
            },
        },
        {
            path: '/register',
            alias: '/registrierung',
            name: 'register',
            component: AuthPage,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Register',
            },
        },
        {
            path: '/forgot/username/',
            alias: '/vergessen/benutzername',
            component: Username,
            beforeEnter: Guard.guest,
            children: [
                {
                    path: '/',
                    component: UsernameForgot,
                    name: 'forgot-username',
                },
            ],
        },
        {
            path: '/forgot/password/',
            alias: '/vergessen/passwort/ ',
            component: Password,
            beforeEnter: Guard.guest,
            children: [
                {
                    path: '/',
                    component: PasswordForgotPage,
                    name: 'forgot-password',
                },
            ],
        },
        {
            path: '/password',
            redirect: {
                name: 'reset-password',
            },
            beforeEnter: Guard.guest,
        },
        {
            path: '/password/reset',
            component: Password,
            beforeEnter: Guard.guest,
            name: 'reset-password',
            redirect: { name: 'get-password-reset-link-page' },
            children: [
                {
                    path: '/',
                    component: PasswordResetForm,
                    name: 'get-password-reset-link-page',
                },
                {
                    path: ':hash',
                    component: PasswordResetPage,
                    name: 'set-new-password',
                },
            ],
        },
        {
            path: '/username/reset',
            component: Username,
            beforeEnter: Guard.guest,
            name: 'reset-username',
            redirect: { name: 'get-username-reset-link-page' },
            children: [
                {
                    path: '/',
                    component: UsernameResetForm,
                    name: 'get-username-reset-link-page',
                },
                {
                    path: ':hash',
                    component: UsernameResetPage,
                    name: 'set-new-username',
                },
            ],
        },
        {
            path: '/2fa/restore',
            component: AuthPage,
            beforeEnter: Guard.guest,
            name: 'restore-two-factor-authentication',
            meta: {
                title: 'Restore',
            },
        },
        {
            path: '/verify',
            component: AuthPage,
            beforeEnter: Guard.guest,
            name: 'verify',
        },
        {
            path: '/super-check-auth-token',
            component: SuperCheckAuthToken,
            beforeEnter: Guard.guest,
            name: 'super-check-auth-token',
        },
        {
            path: '/dashboard/',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: Guard.auth,
            meta: {
                title: 'Dashboard',
            },
        },
        {
            path: '/dashboard/postbox/',
            name: 'postbox',
            component: Dashboard,
            beforeEnter: Guard.auth,
            meta: {
                title: 'Postbox',
            },
        },
        {
            path: '/dashboard/:id',
            name: 'dashboardId',
            component: Dashboard,
            beforeEnter: Guard.auth,
            meta: {
                title: 'Dashboard',
            },
        },
        {
            path: '/dashboard/:id/sales',
            name: 'sales-journey',
            component: SalesJourney,
            beforeEnter: Guard.auth,
            meta: {
                title: 'Sales Journey',
            },
        },
        {
            path: '/journey',
            name: 'journey',
            component: SalesJourney,
            beforeEnter: Guard.auth,
            meta: {
                title: 'Journey',
            },
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Contact',
            },
        },
        {
            path: '/protected',
            name: 'protected',
            component: Protected,
            beforeEnter: Guard.guest,
            meta: {
                title: 'protected',
            },
        },
        {
            path: '/idp',
            name: 'idp-auth-page',
            component: IdpAuthPage,
            beforeEnter: Guard.guest,
            meta: {
                title: 'IDP Auth',
            },
        },
        {
            path: '/auth/idp',
            name: 'auth-idp',
            component: IdpAuthPage,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Auth IDP',
            },
        },
        {
            path: '/meter/reading',
            name: 'meter-reading',
            component: MeterReading,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Zählerstand ohne Account melden',
            },
        },
        {
            path: '/survey',
            name: 'survey',
            component: Survey,
            beforeEnter: Guard.auth,
        },
        {
            path: '/privatkunden/meine-*/services*',
            redirect: {
                name: 'login',
            },
            beforeEnter: Guard.guest,
        },

        // 404 - should be last
        {
            path: '*',
            name: '404',
            component: PageNotFound,
            beforeEnter: Guard.guest,
            meta: {
                title: 'Fehler',
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.fullPath.indexOf('#msdynttrid') >= 0) {
        const newPath = to.fullPath.replace('#msdynttrid', '?msdynttrid');
        next(newPath);
    }
    if (to.name === 'protected' || store.getters['protector/isAllowed']) {
        next();
    } else {
        store.dispatch('protector/setBackPossible');
        next('/protected');
    }
});

export default router;
