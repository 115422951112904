<div
    class="col-12 fullWidthArea mobile-modal__inner"
    data-test="tariff-details-widget"
>
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body">
            <div
                data-test="tariff-details-widget-close-click"
                @click="$emit('closeWidget')"
                id="button-closeTariffDetails"
                class="fullwidthArea-close"
            >
                <i class="fa fa-times"></i>
            </div>
            <div class="box-title static-title">
                {{ $t('widget.td.title') }}
                <button
                    data-test="tariff-details-widget-tariff-overview-click"
                    v-if="tariffOverviewAllowed"
                    class="btn btn-link"
                    @click="$emit('toggleWidget', 'showTariffOverview')"
                >
                    {{ $t('widget.td.tariffOverview') }}
                </button>
            </div>
            <div
                class="row mobile-modal__wrap text-break"
                id="tariffDetails-content"
            >
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div><u>{{ $t('widget.td.delivery') }}</u></div>
                    <span id="tariffDetails-deliveryAddress">
                        {{ contract.deliveryAddress.firstname }} {{
                        contract.deliveryAddress.surname }}<br />
                        {{ contract.deliveryAddress.street }} {{
                        contract.deliveryAddress.houseNumber }}<br />
                        {{ contract.deliveryAddress.zip }} {{
                        contract.deliveryAddress.city }}</span
                    >
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div><u>{{ $t('widget.td.connectionData') }}</u></div>
                    <div>
                        {{ $t('widget.td.counterNumber') }}: {{
                        reading.counter.counterNumber }}
                    </div>
                    <div>
                        {{ $t('widget.td.annualConsumptionForecast') }}: {{
                        tariff.usage | euroFull }} {{ commodity }}
                    </div>
                    <div>
                        {{ $t('widget.td.begin') }}: {{ contract.startDate |
                        date }}
                    </div>
                    <div>
                        <span v-if="contractCancelled">
                            {{ $t('widget.td.end') }}: {{
                            contract.nextCancellationDate | date }}
                        </span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div><u>{{ $t('widget.td.electricityTariff') }}</u></div>
                    <div>
                        {{ $t('widget.td.tariffName') }}: {{ tariff.title }}
                    </div>
                    <div>
                        {{ $t('widget.td.basePrice') }}: {{ tariff.basePrice |
                        euro }} {{ $t('euro') }}/{{ $t('year') }}
                    </div>
                    <div>
                        {{ $t('widget.td.workingPrice') }}:
                        <template v-for="workingPrice in tariff.workingPrices">
                            <template v-if="tariff.workingPrices.length > 1"
                                >{{ workingPrice.registerName }}:
                            </template>
                            {{ workingPrice.workingPriceBrutto | euro }} {{
                            commodityPrice }}
                            <br v-if="tariff.workingPrices.length > 1" />
                        </template>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3">
                    <div v-if="contract.minimumRuntimeUnit">
                        {{ $t('widget.td.minRuntime') }}: {{
                        contract.minimumRuntime }} {{ $t('widget.td.runtime.' +
                        contract.minimumRuntimeUnit) }}
                    </div>
                    <div>
                        {{ $t('widget.td.instantBonus') }}: {{
                        tariff.instantBonus | euro }} €
                    </div>
                    <div
                        v-if="tariff.productBonusPercent || tariff.productBonus"
                    >
                        {{ $t('widget.td.newCustomerBonus') }}:
                        <span v-if="tariff.productBonusPercent">
                            {{ tariff.productBonusPercent | euroFull }} %
                        </span>
                        <span v-else> {{ tariff.productBonus | euro }} € </span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 text-center">
                    <button
                        v-if="contractMoveAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showMoveWidget')"
                    >
                        {{ $t('widget.td.move') }}
                    </button>
                    <button
                        v-if="contractCancellationAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showCancellationWidget')"
                        data-test="widget.td.cancellation"
                    >
                        {{ $t('widget.td.cancellation') }}
                    </button>
                    <button
                        v-if="tariffChangeAllowed"
                        class="btn btn-primary"
                        @click="$emit('toggleWidget', 'showTariffChange')"
                    >
                        {{ $t('widget.td.tariffChange') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
