<form-wrapper form="formCustomerData">
    <div class="mobile-modal__inner" data-test="change-customer-data-widget">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div
                    data-test="change-customer-data-widget-close-click"
                    v-if="!displayElement('hide-close-button')"
                    @click="$emit('closeWidget')"
                    class="fullwidthArea-close change-customer-details-close"
                >
                    <i class="fa fa-times"></i>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            class="col-12"
                            v-if="!details.customerCommunicationType"
                        >
                            <content-loader
                                height="20"
                                primaryColor="var(--secondary)"
                                secondaryColor="var(--primary)"
                                data-test="CONTENT-LOADER"
                            ></content-loader>
                        </div>
                        <template v-else>
                            <div v-if="!customerInformationLoaded">
                                {{ $t('widgets.load.error.message') }}
                            </div>
                            <div v-else>
                                <i
                                    class="far fa-user readingHistory-icon mt-2"
                                    v-if="displayElement('user-icon')"
                                ></i>
                                <i
                                    class="fa fa-user-edit readingHistory-icon mt-2"
                                    v-if="displayElement('user-edit-icon')"
                                ></i>
                                <i
                                    class="fa fa-edit readingHistory-icon mt-2"
                                    v-if="displayElement('edit-icon')"
                                ></i>
                                <span class="box-title static-title"
                                    >{{ $t('widget.ccd.title') }}</span
                                >
                                <span
                                    class="ccd-box-subtitle"
                                    v-if="showSubtitle"
                                    >{{ $t("widget.ccd.subtitle") }}</span
                                >
                                <div
                                    class="actionMiddleWidget"
                                    @click="changeShowContent"
                                    v-if="displayElement('show-more-button')"
                                >
                                    <i class="fa fa-ellipsis-h"></i>
                                </div>

                                <div
                                    id="customerDataChange-content"
                                    class="mobile-modal__wrap"
                                    v-if="showContent"
                                >
                                    <div id="customerDataChangeForm">
                                        <div
                                            v-if="displayElement('disableDataChange')"
                                            class="alert alert-warning"
                                        >
                                            {{
                                            $t('widget.ccd.disableDataChange.warning.1')
                                            }}<br />
                                            {{
                                            $t('widget.ccd.disableDataChange.warning.2')
                                            }}
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col">
                                                <button
                                                    @click="activeTab = tabs[0]"
                                                    :class="[activeTab === tabs[0] ? 'btn-primary' : 'btn-default', 'btn']"
                                                >
                                                    {{
                                                    $t('widget.ccd.personalData')
                                                    }}
                                                </button>
                                            </div>
                                            <div class="col">
                                                <button
                                                    @click="activeTab = tabs[1]"
                                                    :class="[activeTab === tabs[1] ? 'btn-primary' : 'btn-default', 'btn']"
                                                >
                                                    {{
                                                    $t('widget.ccd.addressData')
                                                    }}
                                                </button>
                                            </div>
                                            <div class="col">
                                                <button
                                                    @click="activeTab = tabs[2]"
                                                    :class="[activeTab === tabs[2] ? 'btn-primary' : 'btn-default', 'btn']"
                                                >
                                                    {{
                                                    $t('widget.ccd.phoneEmailData')
                                                    }}
                                                </button>
                                            </div>
                                            <div class="col">
                                                <button
                                                    @click="activeTab = tabs[3]"
                                                    :class="[activeTab === tabs[3] ? 'btn-primary' : 'btn-default', 'btn']"
                                                >
                                                    {{
                                                    $t('widget.ccd.communicationData')
                                                    }}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col form-col">
                                                <form-group
                                                    id="change-customer-data-widget__firstname"
                                                    v-if="activeTab === tabs[0] && isSME"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('register.namestring1')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.firstname.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.firstname.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('register.namestring1')"
                                                        disabled="disabled"
                                                        v-model="details.firstname"
                                                    />
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__lastname"
                                                    v-if="activeTab === tabs[0] && isSME"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('register.namestring2')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.lastname.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.lastname.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('register.namestring2')"
                                                        disabled="disabled"
                                                        v-model="details.surname"
                                                    />
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__company"
                                                    v-if="activeTab === tabs[0] && isSME"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('register.namestring3')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.company.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.company.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('register.namestring3')"
                                                        disabled="disabled"
                                                        v-model="details.company"
                                                    />
                                                </form-group>
                                                <div class="row">
                                                    <form-group
                                                        id="change-customer-data-widget__salutation"
                                                        :class="[displayElement('col-6-field') ? 'col-6' : 'col-12',]"
                                                        v-if="activeTab === tabs[0]"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.salutation')}}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.salutation.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.salutation.tooltip')"
                                                        ></i>
                                                        <custom-v-select
                                                            :options="salutationOptionsVelbert"
                                                            :value="salutationCurrentlySelectedVelbert"
                                                            :forceDisable="true"
                                                            @input="salutationSelectedVelbert"
                                                            @clear="console.log('clear')"
                                                        ></custom-v-select>
                                                        <span></span>
                                                    </form-group>

                                                    <form-group
                                                        id="change-customer-data-widget__postbox"
                                                        v-if="activeTab === tabs[1]"
                                                        class="col-12"
                                                    >
                                                        <div>
                                                            <custom-checkbox
                                                                :options="[{
                                                            value: true,
                                                            label: $t('widget.cbd.postbox.checkbox')
                                                        }]"
                                                                class="form-check-input"
                                                                v-model="showPostboxField"
                                                            />
                                                        </div>
                                                        <div
                                                            v-if="showPostboxField"
                                                        >
                                                            <custom-label
                                                                >{{
                                                                $t('widget.ccd.postbox')
                                                                }}</custom-label
                                                            >
                                                            <custom-input
                                                                type="text"
                                                                :class="['form-control', isPostboxError ? 'form-postbox-error' : '']"
                                                                :placeholder="$t('widget.ccd.postbox')"
                                                                v-model="details.postbox"
                                                            />
                                                        </div>
                                                    </form-group>

                                                    <form-group
                                                        id="change-customer-data-widget__title"
                                                        :class="[displayElement('col-6-field') ? 'col-6' : 'col-12',]"
                                                        v-if="activeTab === tabs[0]"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.titel')
                                                            }}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.titel.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.titel.tooltip')"
                                                        ></i>
                                                        <custom-v-select
                                                            :options="titleOptionsVelbert"
                                                            :value="titleCurrentlySelectedVelbert"
                                                            :placeholder="$t('widget.ccd.titel')"
                                                            :forceDisable="true"
                                                            @input="titleSelectedVelbert"
                                                        ></custom-v-select>
                                                    </form-group>
                                                </div>

                                                <form-group
                                                    id="change-customer-data-widget__firstname"
                                                    v-if="activeTab === tabs[0] && !isSME"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.firstname')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.firstname.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.firstname.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.firstname')"
                                                        :forceDisable="true"
                                                        v-model="details.firstname"
                                                    />
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__lastname"
                                                    v-if="activeTab === tabs[0] && !isSME"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.lastname')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.lastname.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.lastname.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="text"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.lastname')"
                                                        :forceDisable="true"
                                                        v-model="details.surname"
                                                    />
                                                </form-group>

                                                <template
                                                    v-if="!showSelectAddress && activeTab === tabs [1]"
                                                >
                                                    <form-group
                                                        id="change-customer-data-widget__zip"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.zip')
                                                            }}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.zip.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.zip.tooltip')"
                                                        ></i>
                                                        <custom-input
                                                            type="text"
                                                            class="form-control"
                                                            :class="[zipFailed ? 'validation-failed': '']"
                                                            :placeholder="$t('widget.ccd.zip')"
                                                            v-model="details.zip"
                                                        />
                                                        <div
                                                            v-if="this.zipFailed"
                                                            class="alert alert-danger"
                                                        >
                                                            {{
                                                            $t('widget.ccd.zip.failed')
                                                            }}
                                                        </div>
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__city"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.place')
                                                            }}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.place.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.place.tooltip')"
                                                        ></i>
                                                        <custom-input
                                                            type="text"
                                                            class="form-control"
                                                            :placeholder="$t('widget.ccd.place')"
                                                            v-model="details.city"
                                                        />
                                                    </form-group>
                                                </template>
                                                <template
                                                    v-if="showSelectAddress && activeTab === tabs [1]"
                                                >
                                                    <form-group
                                                        id="change-customer-data-widget__zip-select"
                                                    >
                                                        <custom-label
                                                            :class="[cityError ? 'text-danger' : '']"
                                                            >{{
                                                            $t('widget.mo.post.code')
                                                            }}</custom-label
                                                        >
                                                        <custom-v-select
                                                            :options="cities"
                                                            @search="citySearch($event, 'zip')"
                                                            @input="cityInput"
                                                            label="zipText"
                                                            v-model="selectedCityZip"
                                                            :class="[cityError ? 'is-invalid' : '']"
                                                        >
                                                            <template
                                                                #no-options
                                                            >
                                                                {{
                                                                $t('widget.mo.no.city')
                                                                }}
                                                            </template>
                                                        </custom-v-select>
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__city-select"
                                                    >
                                                        <custom-label
                                                            :class="[cityError ? 'text-danger' : '']"
                                                            >{{
                                                            $t('widget.mo.place')
                                                            }}</custom-label
                                                        >
                                                        <custom-v-select
                                                            :options="cities"
                                                            @search="citySearch($event, 'name')"
                                                            @input="cityInput"
                                                            label="nameText"
                                                            v-model="selectedCityName"
                                                            :class="[cityError ? 'is-invalid' : '']"
                                                        >
                                                            <template
                                                                #no-options
                                                            >
                                                                {{
                                                                $t('widget.mo.no.city')
                                                                }}
                                                            </template>
                                                        </custom-v-select>
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__street-select"
                                                        v-if="selectedCityName && activeTab === tabs [1] && !isShowPostboxField"
                                                    >
                                                        <custom-label
                                                            :class="[streetError ? 'text-danger' : '']"
                                                        >
                                                            {{
                                                            $t('widget.mo.street')
                                                            }}
                                                        </custom-label>
                                                        <custom-v-select
                                                            :options="streets"
                                                            v-model="selectedStreet"
                                                            :class="[streetError ? 'is-invalid' : '']"
                                                            @input="streetInput"
                                                            label="name"
                                                        >
                                                            <template
                                                                #no-options
                                                            >
                                                                {{
                                                                $t('widget.mo.no.city')
                                                                }}
                                                            </template>
                                                        </custom-v-select>
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__house-number"
                                                        v-if="selectedCityName && activeTab === tabs [1]"
                                                    >
                                                        <custom-label
                                                            :class="[houseNumberError ? 'text-danger' : '']"
                                                            >{{
                                                            $t('widget.mo.house.number')
                                                            }}</custom-label
                                                        >
                                                        <custom-input
                                                            type="text"
                                                            :class="['form-control', houseNumberError ? 'is-invalid' : '']"
                                                            v-model="details.houseNumber"
                                                            @change="houseNumberError = false"
                                                        />
                                                    </form-group>
                                                </template>
                                                <template
                                                    v-else-if="activeTab === tabs [1] && !isShowPostboxField"
                                                >
                                                    <form-group
                                                        id="change-customer-data-widget__street"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.street')
                                                            }}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.street.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.street.tooltip')"
                                                        ></i>
                                                        <custom-input
                                                            type="text"
                                                            class="form-control"
                                                            :placeholder="$t('widget.ccd.street')"
                                                            v-model="details.street"
                                                        />
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__house-number"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.house')
                                                            }}</custom-label
                                                        >
                                                        <i
                                                            class="fas fa-info-circle"
                                                            v-if="$t('widget.ccd.house.tooltip')"
                                                            v-tooltip.top="$t('widget.ccd.house.tooltip')"
                                                        ></i>
                                                        <custom-input
                                                            type="text"
                                                            class="form-control"
                                                            :placeholder="$t('widget.ccd.house')"
                                                            v-model="details.houseNumber"
                                                        />
                                                    </form-group>
                                                </template>
                                                <form-group
                                                    id="change-customer-data-widget__date-of-birth"
                                                    v-if="activeTab === tabs [0]"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.date')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.date.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.date.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        v-if="displayElement('date-input')"
                                                        v-model="details.dateOfBirth"
                                                        type="date"
                                                        class="form-control"
                                                    />
                                                    <datetime
                                                        v-else
                                                        v-model="details.dateOfBirth"
                                                        type="date"
                                                        input-class="form-control"
                                                        value-zone="local"
                                                        format="dd.LL.yyyy"
                                                    ></datetime>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-mobile"
                                                    v-if="activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.phone')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.phone.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.phone.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <custom-input
                                                                data-test="change-customer-data-widget-input--phone-number"
                                                                type="number"
                                                                class="form-control"
                                                                :placeholder="$t('widget.cdc.phone.full.number')"
                                                                v-model="details.phoneMobile"
                                                                onkeydown="return event.keyCode !== 69"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-mobile-with-areacode"
                                                    v-if="activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.phone')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.phone.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.phone.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <custom-input
                                                                type="text"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.area')"
                                                                v-model="details.phoneMobileAreaCode"
                                                            />
                                                        </div>
                                                        <div class="col-7">
                                                            <custom-input
                                                                type="number"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.number')"
                                                                v-model="details.phoneMobile"
                                                                onkeydown="return event.keyCode !== 69"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-home"
                                                    v-if="displayElement('show-phone-with-code') && activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        v-if="displayElement('customer-phone-label') && !details.company"
                                                        >{{
                                                        $t('widget.ccd.fixedPhone')
                                                        }}</custom-label
                                                    >
                                                    <custom-label v-else
                                                        >{{
                                                        $t('widget.ccd.fixedPrivate')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <custom-input
                                                                type="number"
                                                                class="form-control"
                                                                :placeholder="$t('widget.cdc.phone.full.number')"
                                                                v-model="details.phoneHome"
                                                                onkeydown="return event.keyCode !== 69"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-home-with-areacode"
                                                    v-if="activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        v-if="displayElement('customer-phone-label') && !details.company"
                                                        >{{
                                                        $t('widget.ccd.fixedPhone')
                                                        }}</custom-label
                                                    >
                                                    <custom-label v-else
                                                        >{{
                                                        $t('widget.ccd.fixedPrivate')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <custom-input
                                                                type="text"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.area')"
                                                                v-model="details.phoneHomeAreaCode"
                                                            />
                                                        </div>
                                                        <div class="col-7">
                                                            <custom-input
                                                                type="number"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.number')"
                                                                v-model="details.phoneHome"
                                                                onkeydown="return event.keyCode !== 69"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-business"
                                                    v-if="details.company && activeTab === tabs [2]"
                                                >
                                                    <custom-label>
                                                        {{
                                                        $t('widget.cdc.additional.phone')
                                                        }}
                                                    </custom-label>
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.fixedPrivate.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.fixedPrivate.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <custom-input
                                                                type="number"
                                                                class="form-control"
                                                                :placeholder="$t('widget.cdc.phone.full.number')"
                                                                v-model="details.phoneBusiness"
                                                                onkeydown="return event.keyCode !== 69"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__phone-business-with-areacode"
                                                    v-if="details.company &&  activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.fixedBusiness')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.fixedBusiness.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.fixedBusiness.tooltip')"
                                                    ></i>
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <custom-input
                                                                type="text"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.area')"
                                                                v-model="details.phoneBusinessAreaCode"
                                                            />
                                                        </div>
                                                        <div class="col-7">
                                                            <custom-input
                                                                type="text"
                                                                class="form-control"
                                                                :placeholder="$t('widget.ccd.phone.number')"
                                                                v-model="details.phoneBusiness"
                                                            />
                                                        </div>
                                                    </div>
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__email-private"
                                                    v-if="activeTab === tabs [2]"
                                                >
                                                    <custom-label>
                                                        <template
                                                            v-if="displayElement('customer-email-label') && !details.company"
                                                        >
                                                            {{
                                                            $t('widget.ccd.email')
                                                            }}{{displayElement('email*')
                                                            ? '*' : ''}}
                                                        </template>
                                                        <template v-else>
                                                            {{
                                                            $t('widget.ccd.emailPrivate')
                                                            }}{{displayElement('email*')
                                                            ? '*' : ''}}
                                                        </template>
                                                    </custom-label>
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.emailPrivate.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.emailPrivate.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="email"
                                                        :class="['form-control', isRequiredField.emailPrivate && displayElement('customer-required-field') ? 'is-invalid' : '']"
                                                        :placeholder="$t('widget.ccd.emailPrivate')"
                                                        v-model="details.emailPrivate"
                                                    />
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__email-business"
                                                    v-if="activeTab === tabs [2]"
                                                >
                                                    <custom-label
                                                        >{{
                                                        $t('widget.ccd.emailBusiness')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.emailBusiness.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.emailBusiness.tooltip')"
                                                    ></i>
                                                    <custom-input
                                                        type="email"
                                                        class="form-control"
                                                        :placeholder="$t('widget.ccd.emailBusiness')"
                                                        v-model="details.emailBusiness"
                                                    />
                                                </form-group>
                                                <form-group
                                                    id="change-customer-data-widget__communication-type"
                                                    v-if="activeTab === tabs [3]"
                                                    class="form-group-radio-button"
                                                >
                                                    <custom-label
                                                        :class="[customerCommunicationTypeError ? 'text-danger' : '']"
                                                        >{{
                                                        $t('widget.ccd.customerCommunicationPrefered')
                                                        }}</custom-label
                                                    >
                                                    <i
                                                        class="fas fa-info-circle"
                                                        v-if="$t('widget.ccd.customerCommunicationPrefered.tooltip')"
                                                        v-tooltip.top="$t('widget.ccd.customerCommunicationPrefered.tooltip')"
                                                    ></i>
                                                    <custom-radio
                                                        labelClass="d-flex align-items-center"
                                                        inputClass="margin-mod-right-2"
                                                        :value="communicationTypeSelectedId"
                                                        :options="communicationTypeRadioOptions()"
                                                        @input="communicationTypeInput"
                                                    ></custom-radio>
                                                </form-group>
                                                <div
                                                    v-if="activeTab === tabs [3]"
                                                >
                                                    <div
                                                        v-if="displayElement('customer_ads_mail')"
                                                    >
                                                        <custom-label
                                                            >{{
                                                            $t('widget.ccd.ads.choose')
                                                            }}</custom-label
                                                        >
                                                    </div>
                                                    <form-group
                                                        id="change-customer-data-widget__ads-mail"
                                                    >
                                                        <custom-checkbox
                                                            inputClass="form-check-input"
                                                            v-model="details.adsMail"
                                                            :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                            ]"
                                                            :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.ads.mail').toLocaleString()}`,
                                                                    },
                                                                ]"
                                                        />
                                                    </form-group>

                                                    <form-group
                                                        id="change-customer-data-widget__adsPhone"
                                                        class="form-check"
                                                        v-if="displayElement('customer_ads_phone')"
                                                    >
                                                        <custom-label
                                                            :class="['form-check-label', displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : '']"
                                                        >
                                                            <custom-input
                                                                type="checkbox"
                                                                class="form-check-input"
                                                                v-model="details.adsPhone"
                                                            />
                                                            {{
                                                            $t('widget.ccd.ads.phone')
                                                            }}
                                                            <i></i>
                                                            <span></span>
                                                        </custom-label>
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__ads-phone"
                                                    >
                                                        <custom-checkbox
                                                            inputClass="form-check-input"
                                                            v-model="details.adsPhone"
                                                            :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                            ]"
                                                            :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.ads.phone').toLocaleString()}`,
                                                                    },
                                                                ]"
                                                        />
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__ads-post"
                                                    >
                                                        <custom-checkbox
                                                            inputClass="form-check-input"
                                                            v-model="details.adsPost"
                                                            :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                            ]"
                                                            :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.ads.post').toLocaleString()}`,
                                                                    },
                                                                ]"
                                                        />
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__ads-confirmation"
                                                    >
                                                        <custom-checkbox
                                                            inputClass="form-check-input"
                                                            v-model="details.adsConfirmation"
                                                            :labelClass="[
                                                            'form-check-label',
                                                            displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                                            ]"
                                                            :options="[
                                                                    {
                                                                        value: false,
                                                                        label: `${this.$t('widget.ccd.ads.confirmation').toLocaleString()}`,
                                                                    },
                                                                ]"
                                                        />
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__synchronize-with-billing--excluded"
                                                        v-if="showSyncBilling"
                                                    >
                                                        <custom-checkbox
                                                            type="checkbox"
                                                            inputClass="form-check-input"
                                                            v-model="details.synchronizeWithBilling"
                                                            :options="[
                                                        {value: true, label: `${this.$t('widget.ccd.acceptAsBilling').toLocaleString()}`,}
                                                        ]"
                                                        />
                                                    </form-group>
                                                    <form-group
                                                        id="change-customer-data-widget__all-ads-accepted"
                                                    >
                                                        <custom-checkbox
                                                            class="form-check-input"
                                                            v-model="allAdsAccepted"
                                                            :options="allAdsCheckboxOptions()"
                                                            :labelClass="[
                                                'form-check-label', 'd-inline',
                                                displayElement('branded-checkbox') ? 'm-checkbox m-checkbox--solid m-checkbox--state-brand' : ''
                                            ]"
                                                        />
                                                    </form-group>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                {{
                                                $t('to.change.contact.with.us')
                                                }}
                                            </div>
                                        </div>

                                        <div class="row ccd-form-row-2">
                                            <div class="col-sm-8 col-xs-12">
                                                <messages-block
                                                    fromTestElement="change-customer-data-widget-messages"
                                                    :success="successInformation"
                                                    :errors="errorInformation"
                                                ></messages-block>
                                            </div>
                                            <div
                                                v-if="!displayElement('disableDataChange')"
                                                class="col-sm-4 col-xs-12"
                                            >
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <button
                                                            class="btn btn-link"
                                                            @click="toggleOpenDeleteAccountModal"
                                                        >
                                                            {{
                                                            $t('protected.account.delete')
                                                            }}
                                                        </button>
                                                    </div>
                                                    <div
                                                        class="col-md-6 text-end"
                                                    >
                                                        <button
                                                            data-test="change-customer-data-widget-save-click"
                                                            class="btn btn-primary"
                                                            @click="submitVelbert"
                                                            :disabled="submitButtonDisabled"
                                                        >
                                                            {{
                                                            $t('widget.ccd.submit')
                                                            }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form-wrapper>
