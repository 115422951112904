import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './move-widget.html';

import * as Sentry from '@sentry/browser';

import { Datetime } from 'vue-datetime';
import vSelect from 'vue-select';

import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Auth from '@/interfaces/Auth';
import Contracts from '@/interfaces/Contracts';
import SubmitMove from '@/interfaces/SubmitMove';
import Map from '@/interfaces/Map';

const authProvider = IocContainer.get<Auth>(SERVICES.AUTH);
const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
const mapProvider = IocContainer.get<Map>(SERVICES.MAP);

@WithRender
@Component({
    components: {
        // External packages
        datetime: Datetime,
        'v-select': vSelect,
        'messages-block': MessagesBlock,
    },
})
export class MoveWidget extends Base {
    public submitPending = false;
    public errorInformation: Record<any, any> = [];
    public successInformation: Record<any, any> = [];

    public confirmationCheckboxError = false;
    public departureDateError = false;
    public arrivalDateError = false;
    public cityError = false;
    public streetError = false;
    public houseNumberError = false;
    public newUsageError = false;
    public counterNumberError = false;

    public cities: [] = [];
    public streets: [] = [];
    public selectedCityId: null | number = null;
    public selectedCityZip = null;
    public selectedCityName = null;

    protected details: SubmitMove = {
        contractId: this.$store.state.contracts.contractId,
        departureDate: null,
        arrivalDate: null,
        postcode: null,
        city: null,
        street: null,
        houseNumber: null,
        newUsage: 0,
        counterNumber: null,
        confirmation: false,
    };

    protected elements = {
        buehl: ['hide-street-input-no-options'],
        'dsp-*': [
            'hide-new-usage',
            'move-button',
            'show-counterNumber-tooltip',
            'hide-confirmation-checkbox',
        ],
        'sw-ahrensburg': [
            'hide-new-usage',
            'move-button',
            'show-counterNumber-tooltip',
            'hide-confirmation-checkbox',
        ],
    };

    get requireCounterNumberValidation(): boolean {
        return this.$store.state.settings.requireCounterNumberValidation;
    }

    public sanitizeCounterNumber(): void {
        if (!this.requireCounterNumberValidation) {
            return;
        }
        this.details.counterNumber = this.details.counterNumber.replace(
            /[^A-Z0-9-]/gi,
            ''
        );
    }

    get contract() {
        return this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract;
    }
    get citiesZipCodes() {
        return this.cities.map((item: any) => {
            if (item.zip.length < 5) {
                item.zip = 0 + item.zip;
            }
            return item;
        });
    }

    protected formatSearchZip(code) {
        if (code.charAt(0) === '0') {
            return code.slice(1);
        }
        return code;
    }

    protected created() {
        this.details.contractId = this.$store.state.contracts.contractId;
        this.details.newUsage = this.contract.usage;
    }

    protected citySearch(search) {
        if (search.length >= 3) {
            mapProvider
                .cities(this.contract.energy, this.formatSearchZip(search))
                .then(
                    (response) => {
                        this.cities = response.data.results;
                    },
                    (error) => {
                        Sentry.captureException(new Error(error));
                    }
                );
        } else {
            this.cities = [];
        }
    }

    protected cityInput(value) {
        if (value) {
            this.selectedCityId = value.id;
            this.selectedCityZip = this.details.postcode = value.zip;
            this.selectedCityName = this.details.city = value.name;
            this.cityError = false;

            if (this.selectedCityId) {
                mapProvider
                    .streets(this.contract.energy, this.selectedCityId)
                    .then(
                        (response) => {
                            this.streets = response.data;
                        },
                        (error) => {
                            Sentry.captureException(new Error(error));
                        }
                    );
            }
        } else {
            this.selectedCityId = null;
            this.selectedCityZip = this.details.postcode = null;
            this.selectedCityName = this.details.city = null;
            this.details.street = null;
            this.details.houseNumber = null;
            this.streets = [];
        }
    }

    protected async submit() {
        if (
            !this.details.confirmation &&
            !this.displayElement('hide-confirmation-checkbox')
        ) {
            this.confirmationCheckboxError = true;
            return;
        }

        if (!this.details.departureDate) {
            this.departureDateError = true;
            return;
        }

        if (!this.details.arrivalDate) {
            this.arrivalDateError = true;
            return;
        }

        if (!this.details.postcode || !this.details.city) {
            this.cityError = true;
            return;
        }

        if (!this.details.street) {
            this.streetError = true;
            return;
        }

        if (!this.details.houseNumber) {
            this.houseNumberError = true;
            return;
        }

        if (!this.details.newUsage) {
            this.newUsageError = true;
            return;
        }

        if (!this.details.counterNumber) {
            this.counterNumberError = true;
            return;
        }

        if (authProvider.isAuthenticated()) {
            this.submitPending = true;
            this.errorInformation = [];
            this.successInformation = [];
            await contractsProvider.moveContract(this.details).then(
                (response) => {
                    this.submitPending = false;
                    if (response.data.success) {
                        if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.successInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.successInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.successInformation.push({
                                key: '',
                                message: this.$t('widget.mo.success', {
                                    orderId: response.data.orderId,
                                }).toLocaleString(),
                            });
                        }
                    } else {
                        if (
                            response.data.errorMessages &&
                            response.data.errorMessages.length > 0
                        ) {
                            this.errorInformation = response.data.errorMessages;
                            for (const error of response.data.errorMessages) {
                                if (
                                    error.key &&
                                    error.key === 'rules.counter.number.regex'
                                ) {
                                    error.message = this.$t(
                                        'widget.mo.meter.number.error.regex'
                                    );
                                }
                            }
                        } else if (
                            typeof response.data.messageLocalized === 'object'
                        ) {
                            this.errorInformation.push(
                                response.data.messageLocalized
                            );
                        } else if (
                            typeof response.data.messageLocalized === 'string'
                        ) {
                            this.errorInformation.push({
                                key: '',
                                message: response.data.messageLocalized,
                            });
                        } else {
                            this.errorInformation.push({
                                key: '',
                                message:
                                    this.$t('general.error').toLocaleString(),
                            });
                        }
                    }
                },
                (error) => {
                    this.submitPending = false;
                    this.successInformation = [];
                    this.errorInformation.push({
                        key: '',
                        message: this.$t('general.error').toLocaleString(),
                    });
                    Sentry.captureException(new Error(error));
                }
            );
        }
    }
}
