<div class="m-grid m-grid--hor m-grid--root m-page">
    <div
        class="m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6"
        id="m_login"
    >
        <div
            class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-grid m-grid--hor m-login__aside"
            :style="loginAsideStyle"
            v-if="loginAsideStyle"
        ></div>
        <div
            class="m-grid__item m-grid__item--fluid m-grid__item--order-tablet-and-mobile-1 m-login__wrapper"
        >
            <!--begin::Body-->
            <div class="m-login__body">
                <!--begin::Signin-->
                <div class="m-login__signin">
                    <div class="login-logo">
                        <div :style="loginLogo"></div>
                    </div>

                    <!-- Login Display -->
                    <div class="m-login__title">
                        <h1 data-test="login-title" v-if="!isRegister">
                            {{ $t('login.title') }}
                        </h1>
                        <h1 v-else>{{ $t('registration.title') }}</h1>
                    </div>

                    <!-- Login Subtitle -->
                    <div class="m-login__subtitle">
                        <h6>{{ $t('login.subtitle') }}</h6>
                    </div>

                    <!-- LOGIN AREA-->
                    <login-page
                        v-if="!isRegister && !resetPassword"
                        :second="second"
                    ></login-page>

                    <!-- RESET PASSWORD AREA -->
                    <reset-password-widget
                        v-if="resetPassword"
                    ></reset-password-widget>

                    <!-- REGISTER AREA-->
                    <register-page v-if="isRegister"></register-page>

                    <!-- RESTORE TWO FACTOR AUTHENTICATION AREA -->
                    <restore-two-factor-authentication
                        v-if="isRestoreTwoFactorAuthentication"
                    ></restore-two-factor-authentication>

                    <!-- AUTHPLUS FORCE CHANGE PASSWORD -->
                    <auth-plus-new-password-widget
                        v-if="isForceToChangePassword"
                    ></auth-plus-new-password-widget>

                    <div class="m-login__note" v-if="showLogoutNote">
                        <div class="m-login__note-body">
                            <span>{{ $t('widget.lp.note.heading') }}</span>
                            <div>{{ $t('widget.lp.note.text')}}</div>
                        </div>
                    </div>
                </div>
                <div class="mt-auto row w-100">
                    <hr class="w-100" />
                    <div
                        class="col-12 col-sm-6 col-lg-plus-7 px-0 d-flex justify-content-between"
                    >
                        <div class="me-lg-2">
                            Mo. - Fr. von 8.00 - 19.00 Uhr und<br />
                            Sa. von 9.00 - 15.00 Uhr.
                        </div>
                    </div>
                    <div
                        class="col-12 col-sm-6 col-lg-plus-5 text-center text-sm-right px-0"
                    >
                        <a
                            class="m-link ms-sm-auto"
                            href="https://www.ele.de/de/sonstiges/impressum.html"
                            target="_blank"
                            >{{ $t('login.footer.impressum') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="https://www.ele.de/de/sonstiges/datenschutz.html"
                            target="_blank"
                            >{{ $t('login.footer.privacy') }}</a
                        >
                        <a
                            class="m-link ms-3"
                            href="https://www.ele.de/de/sonstiges/agb.html"
                            target="_blank"
                            >{{ $t('footer.agb') }}</a
                        >
                    </div>
                </div>
                <!--end::Signin-->
            </div>
            <div v-if="locales.length > 1">
                <span
                    v-for="(locale, index) in locales"
                    @click="changeLocale(locale)"
                    >{{ $t('locale.'+locale) }}<span
                        v-if="index !== locales.length - 1"
                    >
                        -
                    </span></span
                >
            </div>

            <!--end::Body-->
        </div>
    </div>
</div>
