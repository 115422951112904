<div class="m-login__form m-form">
    <div
        class="registration-step1-warning m-login__title"
        v-if="displayElement('registration-step1-warning') && isProduction"
    >
        <b>{{ $t('login.step1.warning.title') }}</b>
        <br />
        {{ $t('login.step1.warning.text') }}
        <br />
        <a :href="$t('login.step1.warning.cta')"
            >{{ $t('login.step1.warning.cta.text') }}</a
        >
    </div>
    <div class="form-group m-form__group" v-if="isTFAActive">
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="code">
                <strong>{{ $t('login.code') }}</strong>
                {{ $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors && errors.code ? 'is-invalid' : '']"
                type="text"
                v-model="code"
                id="code"
                name="code"
                autocomplete="off"
                value=""
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.code')"
                v-model="code"
                name="code"
                autocomplete="off"
                value=""
            />
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>
    </div>

    <div v-else>
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="username"
                ><strong>{{ $t('login.username') }}</strong> {{
                $t('login.field.required') }}</label
            >
            <input
                data-test="username"
                :class="['form-control m-input', errors && errors.username ? 'is-invalid' : '']"
                type="text"
                v-model.trim="username"
                id="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                data-test="username"
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.username')"
                v-model.trim="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>
        <div
            class="errors"
            v-if="errors && errors.username && displayElement('input-personal-errors')"
        >
            <div class="text-danger" v-for="error in errors.username">
                <img src="/img/evus/lichtblick/error.svg" alt="error" />
                {{ error }}
            </div>
        </div>
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="password"
                ><strong>{{ $t('login.password') }}</strong> {{
                $t('login.field.required') }}</label
            >
            <input
                data-test="password"
                :class="['form-control m-input m-login__form-input--last', errors && errors.password ? 'is-invalid' : '']"
                @keyup.enter="login()"
                v-on:keyup.enter="login"
                type="password"
                v-model="password"
                id="password"
                name="password"
                autocomplete="off"
            />
        </div>
        <div class="form-group m-form__group" v-else>
            <input
                data-test="password"
                class="form-control m-login__form-input--last m-input"
                @keyup.enter="login()"
                v-on:keyup.enter="login"
                v-model="password"
                type="password"
                :placeholder="$t('login.password')"
                name="password"
                autocomplete="off"
            />
        </div>
        <div
            class="errors"
            v-if="errors && errors.password && displayElement('input-personal-errors')"
        >
            <div class="text-danger" v-for="error in errors.password">
                <img src="/img/evus/lichtblick/error.svg" alt="error" />
                {{ error }}
            </div>
        </div>
        <div
            class="mt-3"
            v-if="!displayElement('input-personal-errors') && (errors.length || Object.keys(errors).length)"
        >
            <show-multiple-error-messages
                :errors="errors"
            ></show-multiple-error-messages>
        </div>
    </div>

    <div class="m-login__action">
        <div v-if="isAuthPlusActive">
            <a
                v-if="isTFAActive"
                @click="$router.push({name: 'restore-two-factor-authentication'})"
                class="m-link"
            >
                <span>{{ $t('login.restore.tfa' )}}</span>
            </a>
            <a
                v-if="displayElement('custom_forgot_links')"
                @click="$router.push({name: 'register'})"
                class="m-link"
            >
                <span>{{ $t('login.account')}}</span>
            </a>
            <a @click="$router.push({name: 'register'})" class="m-link">
                {{ $t('login.start.registration') }}
            </a>
            <a
                v-if="!isTFAActive"
                @click="$router.push({name: 'reset-password'})"
                class="m-link"
            >
                {{ $t('login.reset.passport') }}
            </a>
            <a
                v-if="!isTFAActive"
                @click="$router.push({name: 'reset-username'})"
                class="m-link"
            >
                {{ $t('login.reset.username') }}
            </a>
            <a
                v-if="showMeterReadingLink"
                @click="$router.push({name: 'meter-reading'})"
                class="m-link"
            >
                <span>{{ $t('meter.reading.link' )}}</span>
            </a>
        </div>
        <div v-else-if="!displayElement('separated-sub-links')">
            <a
                v-if="displayElement('show-register-link')"
                @click="$router.push({name: 'register'})"
                class="m-link-register login-actions"
                data-test="auth-page-button-registration"
            >
                <span>{{ $t('login.register.title') }}</span>
            </a>
            <a
                v-if="!displayElement('password-forgotten-bottom')"
                @click="$router.push({name: 'register'})"
                class="m-link login-actions"
                data-test="auth-page-button-registration"
            >
                <span>{{ $t('login.no.account.forget' )}}</span>
            </a>
            <a
                v-if="showMeterReadingLink"
                @click="$router.push({name: 'meter-reading'})"
                class="m-link login-actions"
            >
                <span>{{ $t('meter.reading.link' )}}</span>
            </a>
        </div>

        <!-- Messages -->
        <div class="mt-3 me-auto">
            <div
                class="errors me-auto"
                v-if="displayElement('input-personal-errors') && errors.length"
            >
                <div class="text-danger" v-for="error in errors">
                    <div v-for="msg in error">
                        <img src="/img/evus/lichtblick/error.svg" alt="error" />
                        {{ msg }}
                    </div>
                </div>
            </div>
        </div>

        <button
            v-if="displayElement('button-registration')"
            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary m-btn--gradient-from-light"
            @click="$router.push({name: 'register'})"
            data-test="auth-page-button-registration"
        >
            {{ $t('registration.button') }}
        </button>
        <vue-button-spinner
            v-if="isTFAActive"
            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
            :class="{'disabled': loginLoadingState, 'custom-vue-button-spinner-loading': loginLoadingState}"
            :is-loading="isLoading || loginLoadingState"
            :disabled="isLoading || loginLoadingState"
            :status="status"
        >
            <span @click="loginWithTFA">{{ $t('login.submit') }}</span>
        </vue-button-spinner>
        <vue-recaptcha
            v-if="captchaEnabled"
            v-show="showCaptcha"
            class="justify-content-end"
            :class="[showCaptcha ? 'd-flex' : '']"
            ref="recaptcha"
            @verify="onVerify"
            :loadRecaptchaScript="true"
            :sitekey="googleSiteKey"
            :size="captchaSize"
        >
        </vue-recaptcha>
        <vue-button-spinner
            v-if="(!isTFAActive && !captchaEnabled) || !showCaptcha"
            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner btn-login"
            :class="{'disabled': loginLoadingState, 'custom-vue-button-spinner-loading': loginLoadingState }"
            :is-loading="isLoading || loginLoadingState"
            :disabled="isLoading || loginLoadingState"
            :status="status"
        >
            <span data-test="btn-login" @click="login"
                >{{ $t('login.submit') }}</span
            >
        </vue-button-spinner>

        <br />

        <a
            v-if="displayElement('password-forgotten-bottom') && !isAuthPlusActive"
            @click="$router.push({name: 'register'})"
            class="m-link"
            data-test="auth-page-button-registration"
        >
            <span>{{ $t('login.no.account.forget' )}}</span>
        </a>
    </div>
    <div class="sublinks" v-if="displayElement('separated-sub-links')">
        <a
            v-if="displayElement('show-register-link')"
            @click="$router.push({name: 'register'})"
            class="m-link-register login-actions"
            data-test="auth-page-button-registration"
        >
            <i></i>
            <span>{{ $t('login.register.title') }}</span>
        </a>
        <a
            v-if="!displayElement('password-forgotten-bottom')"
            @click="$router.push({name: 'register'})"
            class="m-link login-actions"
            data-test="auth-page-button-registration"
        >
            <i></i>
            <span>{{ $t('login.no.account.forget' )}}</span>
        </a>
        <a
            v-if="showMeterReadingLink"
            @click="$router.push({name: 'meter-reading'})"
            class="m-link login-actions"
        >
            <i></i>
            <span>{{ $t('meter.reading.link' )}}</span>
        </a>
    </div>
</div>
