<div>
    <!--begin::Form-->
    <div class="m-login__form m-form">
        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="username">
                <strong>{{ $t('login.username') }}</strong> {{
                $t('login.field.required') }}
            </label>
            <input
                :class="['form-control m-input', errors ? 'is-invalid' : '']"
                @keyup.enter="checkToken()"
                type="text"
                v-model="username"
                id="username"
                name="username"
                autocomplete="off"
            />
        </div>

        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                @keyup.enter="checkToken()"
                type="text"
                :placeholder="$t('login.username')"
                v-model="username"
                name="username"
                autocomplete="off"
            />
        </div>

        <div
            class="form-group m-form__group"
            v-if="displayElement('input-with-label')"
        >
            <label for="username">
                <strong>{{ $t('email') }}</strong> {{ $t('login.field.required')
                }}
            </label>
            <input
                :class="['form-control m-input', errors ? 'is-invalid' : '']"
                @keyup.enter="checkToken()"
                type="text"
                v-model="email"
                id="email"
                name="email"
                autocomplete="off"
            />
        </div>

        <div class="form-group m-form__group" v-else>
            <input
                class="form-control m-input"
                @keyup.enter="checkToken()"
                type="text"
                :placeholder="$t('email')"
                v-model="email"
                name="email"
                autocomplete="off"
            />
        </div>

        <template v-if="tokenValid">
            <div
                v-if="displayElement('input-with-label')"
                class="form-group m-form__group"
            >
                <label for="password">
                    <strong>{{ $t('login.password') }}</strong> {{
                    $t('login.field.required') }}
                </label>
                <input
                    :class="['form-control m-input', errors.password ? 'is-invalid' : '']"
                    @keyup.enter="setPassword()"
                    type="password"
                    v-model="password"
                    id="password"
                    name="password"
                    autocomplete="off"
                />
            </div>
            <div v-else class="form-group m-form__group">
                <input
                    class="form-control m-input"
                    @keyup.enter="setPassword()"
                    type="password"
                    :placeholder="$t('login.password')"
                    v-model="password"
                    name="password"
                    autocomplete="off"
                />
            </div>

            <div
                v-if="displayElement('input-with-label')"
                class="form-group m-form__group"
            >
                <label for="passwordConfirmation">
                    <strong>{{ $t('login.password.confirmation') }}</strong> {{
                    $t('login.field.required') }}
                </label>
                <input
                    :class="['form-control m-input', errors.confirmation ? 'is-invalid' : '']"
                    @keyup.enter="setPassword()"
                    type="password"
                    v-model="passwordConfirmation"
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    autocomplete="off"
                />
            </div>
            <div v-else class="form-group m-form__group">
                <input
                    class="form-control m-input"
                    @keyup.enter="setPassword()"
                    type="password"
                    :placeholder="$t('login.password.confirmation')"
                    v-model="passwordConfirmation"
                    name="passwordConfirmation"
                    autocomplete="off"
                />
            </div>
        </template>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>

        <!--begin::Action-->
        <div class="row">
            <div class="m-login__action col-md-6 col-lg-9">
                <div class="mx-3 mb-3 my-lg-0">
                    <a @click="$router.push({name: 'login'})" class="m-link">
                        <span class="register-under-button">
                            {{ $t('login.already.have') }}
                        </span>
                    </a>
                </div>
            </div>
            <div class="m-login__action col-md-6 col-lg-3">
                <button
                    v-if="!tokenValid"
                    @click="checkToken()"
                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                >
                    {{ $t('login.check') }}
                </button>
                <button
                    v-else
                    @click="setPassword()"
                    class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                >
                    {{ $t('login.reset') }}
                </button>
            </div>
        </div>
        <!--end::Action-->
    </div>
    <!--end::Form-->
</div>
