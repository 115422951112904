<div class="m-login__form m-form">
    <div
        class="registration-step1-warning m-login__title"
        v-if="displayElement('registration-step1-warning')"
    >
        <h5>{{ $t('login.step1.warning.title') }}</h5>
        <br />
        <p v-html="$t('login.step1.warning.text')"></p>
    </div>

    <div v-if="isTFAActive">
        <div class="form-group m-form__group">
            <input
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.code')"
                v-model="code"
                name="code"
                autocomplete="off"
                value=""
            />
        </div>

        <messages-block
            :success="successInformation"
            :errors="errorInformation"
        ></messages-block>
    </div>

    <div v-else>
        <div class="form-group m-form__group">
            <input
                data-test="username"
                class="form-control m-input"
                type="text"
                :placeholder="$t('login.username')"
                v-model="username"
                name="username"
                autocomplete="off"
                value=""
            />
        </div>

        <div class="form-group m-form__group">
            <input
                data-test="password"
                class="form-control m-login__form-input--last m-input"
                @keyup.enter="login()"
                v-on:keyup.enter="login"
                v-model="password"
                type="password"
                :placeholder="$t('login.password')"
                name="password"
                autocomplete="off"
            />
        </div>

        <div class="mt-3" v-if="errors.length || Object.keys(errors).length">
            <show-multiple-error-messages
                :errors="errors"
            ></show-multiple-error-messages>
        </div>
    </div>
    <div class="m-login__action flex-wrap">
        <div class="mx-3 mb-3 my-lg-0">
            <a
                @click="$router.push({name: 'register'})"
                class="m-link"
                data-test="auth-page-button-registration"
                ><span>{{ $t('login.no.account.forget' )}}</span></a
            >
        </div>
        <div class="mx-3 mb-3 my-lg-0">
            <a @click="$router.push({name: 'forgot-username'})" class="m-link">
                <span>{{$t('forgot.username')}}</span>
            </a>
        </div>
        <div class="mx-3 mb-3 my-lg-0">
            <a @click="$router.push({name: 'forgot-password'})" class="m-link">
                <span>{{$t('forgot.password')}}</span>
            </a>
        </div>

        <div class="mx-3 mb-3 my-lg-0 helper-button__first">
            <vue-button-spinner
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading"
                :disabled="isLoading || loginLoadingState"
                :status="status"
            >
                <span data-test="btn-helper-login" @click="login"
                    >{{ $t('login.submit') }}</span
                >
            </vue-button-spinner>
        </div>
    </div>
    <div class="helper-button">
        <div class="mx-3 mb-3 my-lg-0">
            <vue-button-spinner
                class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary custom-vue-button-spinner"
                :class="{'custom-vue-button-spinner-loading': loginLoadingState}"
                :is-loading="isLoading"
                :disabled="isLoading || loginLoadingState"
                :status="status"
            >
                <span @click="login">{{ $t('login.submit') }}</span>
            </vue-button-spinner>
        </div>
    </div>
</div>
