import Base from '@/mixins/base';
import { default as Component } from 'vue-class-component';
import WithRender from './auth-plus-new-password-widget.html';

import { AuthPlusPasswordStrengthMeter } from '@/components/snippets/auth-plus-password-strength-meter';
import { MessagesBlock } from '@/components/snippets/messages';

import IocContainer from '@/container/IocContainer';
import Auth from '@/interfaces/Auth';
import SERVICES from '@/container/Services';

import VueButtonSpinner from 'vue-button-spinner';
import { handleNavigationFailure } from '@/router/utils';
import { customParseInt } from '@/customParseInt';

@WithRender
@Component({
    components: {
        AuthPlusPasswordStrengthMeter,
        MessagesBlock,
        VueButtonSpinner,
    },
})
export class AuthPlusNewPasswordWidget extends Base {
    protected isLoading = false;
    protected status = '';
    protected errorInformation: Record<any, any> = [];
    private errors: any[] = [];

    protected elements = {
        bsw: ['input-with-label'],
        'dsp-*': ['auth-plus-password-strength-meter'],
        'sw-ahrensburg': ['auth-plus-password-strength-meter'],
    };

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private password = '';
    private passwordConfirmation = '';
    protected totpCode = '';

    get username(): string {
        return this.$store.state.auth.username;
    }

    get isTFAActive(): boolean {
        return this.$store.state.auth.isTFAActive;
    }

    public setPassword() {
        this.isLoading = true;
        this.errorInformation = [];

        this.auth
            .updateAuthPlusPassword({
                username: this.username,
                password: this.password,
                confirmPassword: this.passwordConfirmation,
                totpCode: this.totpCode,
            })
            .then(
                (response) => {
                    this.isLoading = false;

                    if (response && response.data && response.data.success) {
                        if (
                            typeof response.data.authToken !== 'undefined' &&
                            response.data.authToken
                        ) {
                            this.auth.authenticated(response.data.authToken);

                            this.$store.dispatch('auth/set', true);
                            this.$store.dispatch('auth/errorTryReset');

                            if (
                                this.currentClient === 'velbert' ||
                                this.currentClient === 'ele'
                            ) {
                                this.$store
                                    .dispatch('contracts/contracts')
                                    .then(() => {
                                        this.isLoading = false;
                                        this.$store
                                            .dispatch(
                                                'contracts/setContractId',
                                                customParseInt(
                                                    this.contractsList[0]
                                                        .contractId,
                                                    10
                                                )
                                            )
                                            .then(() => {
                                                this.$router
                                                    .push({
                                                        name: 'dashboardId',
                                                        params: {
                                                            id: this
                                                                .contractsList[0]
                                                                .contractId,
                                                        },
                                                    })
                                                    .catch((failure) =>
                                                        handleNavigationFailure(
                                                            failure
                                                        )
                                                    );
                                            });
                                    });
                            } else {
                                this.isLoading = false;
                                this.$router
                                    .push({ name: 'dashboard' })
                                    .catch((failure) =>
                                        handleNavigationFailure(failure)
                                    );
                            }
                        }
                    } else {
                        if (
                            response.data.message &&
                            response.data.message === 'auth.plus.tfa.code.error'
                        ) {
                            this.errorInformation.push({
                                key: 'auth.plus.tfa.code.error',
                                message: this.$t('widget.modal.tfa.code.error'),
                            });
                        } else {
                            if (response.data.forceChangePassword) {
                                this.$store.dispatch(
                                    'auth/forceChangePassword',
                                    this.username
                                );

                                return;
                            }

                            if (
                                this.currentClient === 'velbert' ||
                                this.currentClient === 'ele'
                            ) {
                                this.errors.push([
                                    response.data.response.messageLocalized,
                                ]);
                            } else {
                                this.errors.push([this.$t('login.user.error')]);
                            }
                            this.$store.dispatch('auth/incrementErrorTry');
                        }
                    }
                },
                (error) => {
                    this.isLoading = false;

                    if (
                        error.response.data.errors &&
                        error.response.data.errors.password
                    ) {
                        const bucket: string[] = [];

                        for (const i in error.response.data.errors.password) {
                            if (!error.response.data.errors.password[i]) {
                                continue;
                            }

                            const errorTranslations = {
                                isPassportInBlacklist:
                                    'auth.plus.error.password.in.blacklist',
                                isErrorPasswordLength:
                                    'auth.plus.error.password.length',
                                isErrorCountSpecialCharacters:
                                    'auth.plus.error.password.count.special.characters',
                                isErrorCountUppercaseCharacters:
                                    'auth.plus.error.password.count.upper.characters',
                                isErrorCountLowercaseCharacters:
                                    'auth.plus.error.password.count.lower.characters',
                                isErrorCountNumbers:
                                    'auth.plus.error.password.count.numbers',
                                isErrorCountHistoryPasswords:
                                    'auth.plus.error.password.in.history',
                                isErrorDenyUsernamePasswords:
                                    'auth.plus.error.password.deny.username',
                                isErrorMinimumAgePassword:
                                    'auth.plus.error.password.minimum.age',
                            };

                            let message =
                                error.response.data.errors.password[i];

                            if (errorTranslations[message]) {
                                message = this.$t(errorTranslations[message]);
                                bucket.push(message);
                            } else {
                                if (
                                    this.$te(
                                        error.response.data.errors.password[i]
                                    )
                                ) {
                                    bucket.push(
                                        this.$t(
                                            error.response.data.errors.password[
                                                i
                                            ]
                                        ).toString()
                                    );
                                } else {
                                    bucket.push(
                                        error.response.data.errors.password[i]
                                    );
                                }
                            }
                        }

                        this.errorInformation.push({
                            key: '',
                            message: bucket.join('\r\n'),
                        });
                    } else if (
                        error.response.data.errors &&
                        error.response.data.errors.confirmPassword
                    ) {
                        this.errorInformation.push({
                            key: '',
                            message: this.$t(
                                'auth.plus.error.password.confirmation.not.equal'
                            ),
                        });
                    }
                }
            );
    }
}
