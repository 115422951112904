import { RequestPasswordResetWidgetWarmeenergie } from '@/components/widgets/request-password-reset-widget/custom/velbert';
import { RequestPasswordResetWidget } from '@/components/widgets/request-password-reset-widget/request-password-reset-widget';

let widget;

if (
    process.env.VUE_APP_ENVIRONMENT === 'velbert' ||
    process.env.VUE_APP_ENVIRONMENT === 'ele'
) {
    widget = RequestPasswordResetWidgetWarmeenergie;
} else {
    widget = RequestPasswordResetWidget;
}
export { widget as RequestPasswordResetWidget };
