import Vue from 'vue';
import Base from '@/mixins/base';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import moment from 'moment';

// Call Render template.
import WithRender from './dashboard.html';

// Template
import { Header } from '../../template/header';
import { Footer } from '../../template/footer';
import { LeftAside } from '../../template/left-aside';
import { ScrollTop } from '../../template/scroll-top';

// Widgets
import { AgentViewWidget } from '../../widgets/agent-view-widget';
import { AccountBalanceWidget } from '../../widgets/account-balance-widget';
import { AccountTransactionsWidget } from '../../widgets/account-transactions-widget';
import { ChangeBillingDataWidget } from '../../widgets/change-billing-data-widget';
import { ChangeCustomerDataWidget } from '../../widgets/change-customer-data-widget';
import { ChangePaymentDataWidget } from '../../widgets/change-payment-data-widget';
import { ChangeWidget } from '../../widgets/change-widget';
import { ContentLoader } from 'vue-content-loader';
import { CustomerDataCorrectionWidget } from '../../widgets/customer-data-correction-widget';
import { DepositChangeWidget } from '../../widgets/deposit-change-widget';
import { DepositWidget } from '../../widgets/deposit-widget';
import { InvoiceWidget } from '../../widgets/invoice-widget';
import { RecommendationWidget } from '../../widgets/recommendation-widget';
import { MeterHistoryWidget } from '../../widgets/meter-history-widget';
import { MeterSubmitWidget } from '../../widgets/meter-submit-widget';
import { MeterWidget } from '../../widgets/meter-widget';
import { UsageHistoryWidget } from '../../widgets/usage-history-widget';
import { OptInWidget } from '../../widgets/opt-in-widget';
import { PostBoxWidget } from '../../widgets/post-box-widget';
import { PostBoxCombinedWidget } from '../../widgets/post-box-combined-widget';
import { TariffAvailableWidget } from '../../widgets/tariff-available-widget/custom/qcells';
import { TariffDetailsWidget } from '../../widgets/tariff-details-widget';
import { TariffWidget } from '../../widgets/tariff-widget';
import { StatusBarWidget } from '../../widgets/status-bar-widget';
import { WelcomeWidget } from '../../widgets/welcome-widget';
import { CustomerServiceFormWidget } from '../../widgets/customer-service-form-widget';
import { CustomerServiceWidget } from '../../widgets/customer-service-widget';
import { SalesWidget } from '../../widgets/sales-widget';
import { CancellationWidget } from '../../widgets/cancellation-widget';
import { MoveWidget } from '../../widgets/move-widget';
import { TariffChangeWidget } from '../../widgets/tariff-change-widget';
import { TariffRecommendation } from '@/components/widgets/tariff-recommendation';
import { TariffOverviewWidget } from '../../widgets/tariff-overview-widget';
import { FuturePaymentOverview } from '../../widgets/future-payment-overview';
import { DepositOverviewWidget } from '@/components/widgets/deposit-overview-widget';
import { NonCommodityTariffDetailsWidget } from '@/components/widgets/non-commodity-tariff-details-widget';
import { CookieBannerWidget } from '@/components/widgets/cookie-banner-widget';
import { AdsPopupWidget } from '@/components/widgets/ads-popup-widget';
import { ModalPasswordUpdateWidget } from '@/components/widgets/modal-password-update-widget';
import { ModalDeleteAccountWidget } from '@/components/widgets/modal-delete-account-widget';
import { ModalMessageWidget } from '@/components/widgets/modal-message-widget';
import { ModalTwoFactorAuthenticationWidget } from '@/components/widgets/modal-two-factor-authentication-widget';
import { EcarUploadWidget } from '@/components/widgets/ecar-upload-widget';
import { RevokeWidget } from '@/components/widgets/revoke-widget';
import { EnergyCompoundWidget } from '@/components/widgets/energy-compound-widget';
import { AdvizzoDisaggregationWidget } from '@/components/widgets/advizzo/disaggregation-widget';
import { AdvizzoNeighborComparisonWidget } from '@/components/widgets/advizzo/neighbor-comparison-widget';
import { AdvizzoTipsWidget } from '@/components/widgets/advizzo/tips-widget';
import { AdvizzoConsumptionGraphWidget } from '@/components/widgets/advizzo/consumption-graph';
import { AdvizzoElectricityProductionWidget } from '@/components/widgets/advizzo/electricity-production-widget';
import { CallToActionBannerWidget } from '@/components/widgets/call-to-action-banner-widget';
import { BannerWidget } from '@/components/widgets/banner-widget';
import { QenergyWidget } from '@/components/widgets/qenergy-widget';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Contracts from '@/interfaces/Contracts';
import Customer from '@/interfaces/Customer';
import Auth from '@/interfaces/Auth';
import { handleNavigationFailure } from '@/router/utils';
import { AddonStatusWidget } from '@/components/widgets/addon-status-widget/addon-status-widget';
import { HotlineWidget } from '@/components/widgets/hotline-widget';
import { AklamioPopupWidget } from '@/components/widgets/aklamio-popup';
import { DonationWidget } from '@/components/widgets/donation-widget';

const contractsProvider = IocContainer.get<Contracts>(SERVICES.CONTRACTS);
const customerProvider = IocContainer.get<Customer>(SERVICES.CUSTOMER);
import { customParseInt } from '@/customParseInt';

Vue.config.ignoredElements = ['wd-sales-widget'];

@WithRender
@Component({
    components: {
        // Template
        'app-header': Header,
        'app-footer': Footer,
        'left-aside': LeftAside,
        'scroll-top': ScrollTop,
        // External packages
        // Widgets
        'agent-view-widget': AgentViewWidget,
        'account-balance-widget': AccountBalanceWidget,
        'account-transactions-widget': AccountTransactionsWidget,
        'change-billing-data-widget': ChangeBillingDataWidget,
        'change-customer-data-widget': ChangeCustomerDataWidget,
        'change-payment-data-widget': ChangePaymentDataWidget,
        'change-widget': ChangeWidget,
        'content-loader': ContentLoader,
        'customer-data-correction-widget': CustomerDataCorrectionWidget,
        'deposit-change-widget': DepositChangeWidget,
        'deposit-overview-widget': DepositOverviewWidget,
        'deposit-widget': DepositWidget,
        'invoice-widget': InvoiceWidget,
        'recommendation-widget': RecommendationWidget,
        'opt-in-widget': OptInWidget,
        'meter-history-widget': MeterHistoryWidget,
        'meter-submit-widget': MeterSubmitWidget,
        'meter-widget': MeterWidget,
        'usage-history-widget': UsageHistoryWidget,
        'post-box-widget': PostBoxWidget,
        'post-box-combined-widget': PostBoxCombinedWidget,
        'tariff-details-widget': TariffDetailsWidget,
        'tariff-widget': TariffWidget,
        'welcome-widget': WelcomeWidget,
        'status-bar-widget': StatusBarWidget,
        'customer-service-form-widget': CustomerServiceFormWidget,
        'customer-service-widget': CustomerServiceWidget,
        'sales-widget': SalesWidget,
        'cancellation-widget': CancellationWidget,
        'move-widget': MoveWidget,
        'tariff-available-widget': TariffAvailableWidget,
        'tariff-change-widget': TariffChangeWidget,
        'tariff-recommendation-widget': TariffRecommendation,
        'tariff-overview-widget': TariffOverviewWidget,
        'non-commodity-tariff-details-widget': NonCommodityTariffDetailsWidget,
        'cookie-banner-widget': CookieBannerWidget,
        'ads-popup-widget': AdsPopupWidget,
        'modal-password-update-widget': ModalPasswordUpdateWidget,
        'modal-delete-account-widget': ModalDeleteAccountWidget,
        'modal-message-widget': ModalMessageWidget,
        'modal-two-factor-authentication-widget':
            ModalTwoFactorAuthenticationWidget,
        'revoke-widget': RevokeWidget,
        'energy-compound-widget': EnergyCompoundWidget,
        'ecar-upload-widget': EcarUploadWidget,
        'future-payment-overview': FuturePaymentOverview,
        'advizzo-disaggregation-widget': AdvizzoDisaggregationWidget,
        'advizzo-neighbor-comparison-widget': AdvizzoNeighborComparisonWidget,
        'advizzo-tips-widget': AdvizzoTipsWidget,
        'addon-status-widget': AddonStatusWidget,
        'advizzo-consumption-chart': AdvizzoConsumptionGraphWidget,
        'advizzo-electricity-production-widget':
            AdvizzoElectricityProductionWidget,
        'call-to-action-banner-widget': CallToActionBannerWidget,
        'banner-widget': BannerWidget,
        'qenergy-widget': QenergyWidget,
        'hotline-widget': HotlineWidget,
        'aklamio-popup-widget': AklamioPopupWidget,
        'donation-widget': DonationWidget,
    },
    metaInfo() {
        return {
            meta: [
                {
                    name: 'description',
                    content: '',
                    template: () =>
                        `${
                            process.env.VUE_APP_META_DESCRIPTION
                                ? process.env.VUE_APP_META_DESCRIPTION
                                : ''
                        }`,
                },
            ],
        };
    },
})
export class Dashboard extends mixins(Vue, Base) {
    public toggleSidebarState = false;
    public showChangePasswordModal = false;
    public showDeleteAccountModal = false;
    public showMessageModal = false;
    public showTwoFactorAuthenticationModal = false;

    public forceCloseRecommendationWidget = false;

    protected showGroup1 = {
        depositChangeWidget: false,
        accountTransactionsWidget: false,
        meterSubmitWidget: false,
    };
    protected showGroup2 = {
        changeBillingDataWidget: false,
        changeCustomerDataWidget: false,
        changePaymentDataWidget: false,
    };
    protected showGroup3 = {
        tariffDetailsWidget: false,
    };
    protected showGroup4 = {
        showCancellationWidget: false,
        showDepositOverview: false,
        showMoveWidget: false,
        showTariffChange: false,
        showTariffOverview: false,
        revokeWidget: false,
        showEnergyCompoundWidget: false,
        showFuturePaymentDetailsOverviewWidget: false,
        showTariffRecommendationWidget: true,
        showDonation: false,
    };

    protected showCustomerDataCorrectionWidget = false;
    protected dataCorrectionRequiredResponse = { success: false };
    protected adsPopupDate: string | null = null;
    protected showAgreeAdsPopup = false;
    protected customerDetailsLoaded = false;

    protected hideMeterHistoryWidget = false;
    protected showUsageHistoryWidget = false;

    private auth = IocContainer.get<Auth>(SERVICES.AUTH);
    private ignoreRedirectClients = ['velbert', 'ele'];
    protected usageHistoryReferenceValues?: number[] = undefined;

    protected elements = {
        bsw: [
            'welcome-widget-empty',
            'app-footer-inside-container',
            'hide-app-footer-outside-container',
        ],
        qcells: [
            'cookie-banner',
            'show-ads-popup',
            'show-tariff-available-promoted',
            'show-banner',
            'show-aklamio-popup',
        ],
        velbert: ['no-contracts-logout'],
        ele: ['no-contracts-logout'],
        prokon: ['show-meter-widget-in-waiting-state', 'show-ads-popup'],
        advizzo: ['advizzo-widget'],
        'elli*': [
            'hide-app-footer-outside-container',
            'open-meter-submit-one-month-before-due',
        ],
        'dsp-ahrensburg': ['hide-app-footer-outside-container'],
        'sw-ahrensburg': ['hide-app-footer-outside-container'],
    };

    @Watch('contractsList')
    @Watch('customerDetails.customerInformationLoaded')
    public onContractsDataLoad(newVal: number): void {
        // Redirect on contractsData available
        if (
            this.$route.name === 'dashboard' &&
            this.shouldRedirect &&
            this.customerDetails.customerInformationLoaded &&
            !this.customerDetails.isFleetCustomer
        ) {
            if (this.currentClient !== 'ewii') {
                if (newVal[0] && newVal[0].contractId) {
                    this.$store.dispatch(
                        'contracts/setContractId',
                        customParseInt(newVal[0].contractId, 10)
                    );
                    this.$router
                        .push({
                            name: 'dashboardId',
                            params: { id: newVal[0].contractId },
                        })
                        .catch((failure) => handleNavigationFailure(failure));
                }
            }
        }

        if (this.$route.name === 'dashboardId' && this.contractsList[0]) {
            const listOfContractIds = this.contractsList.map((contract) => {
                return customParseInt(contract.contractId, 10);
            });
            if (
                listOfContractIds.indexOf(
                    customParseInt(this.$route.params.id, 10)
                ) === -1
            ) {
                window.location.pathname = '/dashboard';
            }
            this.$store.dispatch(
                'tariff/overview',
                customParseInt(this.$route.params.id, 10)
            );
            this.$store.dispatch(
                'tariff/details',
                customParseInt(this.$route.params.id, 10)
            );
        }
        if (this.currentClient === 'sachsenenergie') {
            this.showGroup3.tariffDetailsWidget = true;
        }
    }

    get nonCommodityFulfillmentEnabled(): boolean {
        return this.$store.state.settings.nonCommodityFulfillmentEnabled;
    }

    get addonStatus(): any {
        return this.$store.state.contracts.addonStatus;
    }

    get paymentState(): any {
        return this.$store.getters['payment/getState'](this.contractId);
    }

    get invoicesLoaded(): any {
        return this.paymentState?.invoicesLoaded;
    }

    get contractId(): number {
        return this.$store.state.contracts.contractId;
    }

    get invoiceCount(): number {
        return this.paymentState?.invoices?.length;
    }

    protected setupCustomerDashboard() {
        return;
    }

    protected created(): void {
        this.setupCustomerDashboard();
        this.$store.dispatch('contracts/setContractId', null);

        if (
            this.$route.params.id &&
            !customParseInt(this.$route.params.id, 10)
        ) {
            window.location.pathname = '/dashboard';
        } else {
            if (
                this.$route.params.id &&
                customParseInt(this.$route.params.id, 10)
            ) {
                this.$store.dispatch(
                    'contracts/setContractId',
                    customParseInt(this.$route.params.id, 10)
                );
                this.$store.dispatch(
                    'payment/init',
                    customParseInt(this.$route.params.id, 10)
                );
                this.$store.dispatch(
                    'reading/init',
                    customParseInt(this.$route.params.id, 10)
                );
                this.$store.dispatch(
                    'tariff/init',
                    customParseInt(this.$route.params.id, 10)
                );
            }
            this.$store.dispatch('customer/getDetails');

            this.$store.dispatch('contracts/contracts').then(() => {
                if (
                    this.displayElement('no-contracts-logout') &&
                    this.contractsDataLength === 0
                ) {
                    this.auth.logout(this.$store.state.settings.idpLogoutUrl);
                }
            });

            // data that should be loaded based on settings
            if (this.settings.adsBonusActive) {
                this.$store.dispatch('customer/adsBonusAvailable');
            }

            this.showTwoFactorAuthenticationModal =
                localStorage.getItem('2FA-enabling') === 'true';
        }
    }

    get isDemoMode(): boolean {
        return this.$store.state.settings.isDemo;
    }

    get settings(): Record<any, any> {
        return this.$store.state.settings;
    }

    get contractsDataLength(): number {
        return this.contractsList !== null ? this.contractsList.length : 0;
    }

    get contractsDataSet(): boolean {
        return (
            this.contractsList.length > 0 &&
            this.$store.state.contracts.contractId !== null
        );
    }

    get noContractsAvailable(): boolean {
        return this.isContractsAvailable && this.contractsList.length === 0;
    }

    get contractStatus(): Record<any, any> {
        let contractInfo = {
            contractId: null,
            status: null,
            statusCode: 0,
            statusCodeTitle: null,
        };
        if (this.contractsDataSet) {
            const selectedContractInfo =
                this.$store.state.contracts.list.filter((contract) => {
                    return (
                        contract.contractId ===
                        this.$store.state.contracts.contractId
                    );
                })[0];
            if (selectedContractInfo) {
                contractInfo = selectedContractInfo;
            }
        }
        return contractInfo;
    }

    // Widget Visibility Configuration

    get showDepositWidget(): boolean {
        return (
            this.shouldDisplayWidgetByContractStatus('depositWidget') &&
            !this.isNonCommodityContract
        );
    }

    get showCallToActionBannerWidget(): boolean {
        return this.$store.state.settings.callToActionBannerActivated;
    }

    get showAccountBalanceWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('accountBalanceWidget');
    }

    get showMeterWidget(): boolean {
        let shouldDisplay =
            this.shouldDisplayWidgetByContractStatus('meterWidget');
        if (!shouldDisplay && this.shouldConsiderDurationAfterCancellation) {
            shouldDisplay = true;
        }
        if (
            this.contractInWaitingState &&
            this.displayElement('show-meter-widget-in-waiting-state')
        ) {
            return !this.isNonCommodityContract;
        }
        return shouldDisplay && !this.isNonCommodityContract;
    }

    get showTariffWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('tariffWidget');
    }

    get showTariffAvailableWidget(): boolean {
        if (
            this.$store.state.settings.tariffChange &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ) &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).changeList.length === 1 &&
            this.displayElement('show-tariff-available-promoted')
        ) {
            return true;
        }
        return false;
    }

    get showMeterHistoryWidget(): boolean {
        return (
            this.shouldDisplayWidgetByContractStatus('meterHistoryWidget') &&
            !this.isNonCommodityContract &&
            this.isNoDashboardRedirect
        );
    }

    get showRecommendationWidget(): boolean {
        return this.$store.state.settings.bonusRecommendationProgramEnabled;
    }

    get showInvoiceWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('invoiceWidget');
    }

    get showChangeWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('changeWidget');
    }

    get showCustomerServiceWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus(
            'customerServiceWidget'
        );
    }

    get showStatusBarWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('statusBarWidget');
    }

    get showPostBoxWidget(): boolean {
        return this.shouldDisplayWidgetByContractStatus('postBoxWidget');
    }

    get showTariffDetailsWidget(): boolean {
        return (
            this.showGroup3.tariffDetailsWidget &&
            this.shouldDisplayWidgetByContractStatus('tariffDetailsWidget')
        );
    }

    get showCustomerServiceFormWidget(): boolean {
        return this.settings.enableTicketSystem;
    }

    get showSalesWidget(): boolean {
        return (
            this.settings.salesCampaign !== null &&
            this.settings.salesCampaign !== '' &&
            this.settings.salesWidgetLink !== null &&
            this.settings.salesWidgetLink !== '' &&
            this.settings.salesJourneyLink !== null &&
            this.settings.salesJourneyLink !== ''
        );
    }

    get showOptInWidget(): boolean {
        return (
            this.shouldDisplayWidgetByContractStatus('optInWidget'),
            !this.customerDetails.adsMail &&
                this.settings.adsBonusActive &&
                this.$store.state.customer.adsBonusAvailable &&
                !this.showAgreeAdsPopup
        );
    }

    get isNonCommodityContract(): boolean {
        const selectContract = this.contractsList.filter((contract) => {
            return (
                contract.contractId === this.$store.state.contracts.contractId
            );
        })[0];
        return (
            selectContract &&
            selectContract.type === 'non_commodity' &&
            selectContract.energy === 'non_energy'
        );
    }

    get shouldConsiderDurationAfterCancellation(): boolean {
        if (this.$store.state.settings.dashboard['meterDurationAfterCancel']) {
            if (
                this.contract.endDate &&
                this.contract.statusCode &&
                parseInt(this.contract.statusCode) >= 6000 &&
                parseInt(this.contract.statusCode) < 8000
            ) {
                const duration =
                    this.$store.state.settings.dashboard[
                        'meterDurationAfterCancel'
                    ].split(' ');
                if (
                    moment(this.contract.endDate)
                        .add(duration[0], duration[1])
                        .isSameOrBefore(moment())
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    get isNoDashboardRedirect(): boolean {
        return !(this.$route.name === 'dashboard' && this.shouldRedirect);
    }
    get isUsageHistoryWidgetEnabled(): boolean {
        return this.$store.state.settings.enableUsageHistoryChart;
    }
    get contractEndDateIsInvoicePeriodEnd(): boolean {
        return (
            this.$store.getters['payment/getState'](
                this.$store.state.contracts.contractId
            ).invoices.filter((invoice) => {
                return (
                    moment(invoice.periodEnd).format('YYYY-MM-DD') ===
                    this.$store.getters['tariff/getState'](
                        this.$store.state.contracts.contractId
                    ).contract.endDate
                );
            }).length === 1
        );
    }

    // Show Widget Actions

    protected toggleHistoryWidget(): void {
        this.showUsageHistoryWidget = !this.showUsageHistoryWidget;
        this.hideMeterHistoryWidget = !this.hideMeterHistoryWidget;
    }

    get showDepositWidgetAction(): boolean {
        return this.shouldDisplayWidgetByContractStatus('depositWidgetAction');
    }

    get showAccountBalanceWidgetAction(): boolean {
        return this.shouldDisplayWidgetByContractStatus(
            'accountBalanceWidgetAction'
        );
    }

    get showMeterWidgetAction(): boolean {
        const shouldDisplay =
            this.shouldDisplayWidgetByContractStatus('meterWidgetAction');
        if (!shouldDisplay && this.shouldConsiderDurationAfterCancellation) {
            return true;
        }
        if (['elli'].includes(this.currentClient)) {
            return (
                shouldDisplay ||
                (this.contractInExpiredState &&
                    !this.contractEndDateIsInvoicePeriodEnd)
            );
        }
        return shouldDisplay;
    }

    get showRecommendTariffWidget(): boolean {
        if (
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ) &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).recommended &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).recommendationUntil
        ) {
            return (
                this.$store.getters['tariff/getState'](
                    this.$store.state.contracts.contractId
                ).recommended.length > 0 &&
                moment(
                    this.$store.getters['tariff/getState'](
                        this.$store.state.contracts.contractId
                    ).recommendationUntil,
                    'YYYY-MM-DD'
                ).diff(moment()) > 0
            );
        }
        return false;
    }

    get shouldRedirect(): boolean {
        return this.ignoreRedirectClients.indexOf(this.currentClient || '') < 0;
    }

    // Style
    get widgetsBackground(): string {
        return (
            'background-image: url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/background.jpg), url(/img/evus/' +
            process.env.VUE_APP_ENVIRONMENT +
            '/background.png); background-size: cover, cover;'
        );
    }

    get bodyClass(): { [className: string]: boolean } {
        return {
            'm-brand--minimize m-aside-left--minimize m-aside-left--on':
                this.toggleSidebarState,
        };
    }

    get stateHasLoaded(): boolean {
        const id = this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        ).contract.id;
        return id !== undefined && id !== null;
    }

    // Show / Hide widgets state
    // showGroup1
    get showDepositChangeWidget(): boolean {
        return this.showGroup1.depositChangeWidget ? true : false;
    }
    get showAccountTransactionsWidget(): boolean {
        return this.showGroup1.accountTransactionsWidget ? true : false;
    }

    get daysUntilNextMeterSubmit(): number {
        return this.$store.getters['reading/getState'](
            this.$store.state.contracts.contractId
        ).next.daysUntil;
    }

    get isMeterSubmitOpenOneMonthBeforeDue(): boolean {
        return (
            this.displayElement('open-meter-submit-one-month-before-due') &&
            this.daysUntilNextMeterSubmit <= 30 &&
            !this.isNonCommodityContract
        );
    }

    get meterState() {
        if (this.$store.state.contracts.contractId) {
            return this.$store.getters['reading/getState'](
                this.$store.state.contracts.contractId
            ).next.state;
        }

        return null;
    }

    get showMeterSubmitWidget(): boolean {
        return (
            this.meterState &&
            this.meterState !== 'closed' &&
            this.contract.statusCode >= 5000 &&
            this.showMeterWidget &&
            (this.isMeterSubmitOpenOneMonthBeforeDue ||
                this.showGroup1.meterSubmitWidget)
        );
    }

    // showGroup2
    get showChangeBillingDataWidget(): boolean {
        return this.showGroup2.changeBillingDataWidget ? true : false;
    }
    get showChangeCustomerDataWidget(): boolean {
        return this.showGroup2.changeCustomerDataWidget ? true : false;
    }
    get showChangePaymentDataWidget(): boolean {
        return this.showGroup2.changePaymentDataWidget ? true : false;
    }
    // showGroup3
    get showRevocationWidget(): boolean {
        return this.showGroup4.revokeWidget ? true : false;
    }
    // showGroup4
    get showDepositOverviewWidget(): boolean {
        return (
            this.showGroup4.showDepositOverview &&
            this.showGroup1.depositChangeWidget
        );
    }
    get showFuturePaymentOverview(): boolean {
        return (
            this.showGroup4.showFuturePaymentDetailsOverviewWidget &&
            this.showGroup2.changePaymentDataWidget
        );
    }

    get showTariffChangeWidget(): boolean {
        return (
            this.showGroup4.showTariffChange &&
            this.contractStatus.statusCode < 6000
        );
    }

    get showPriceInformationAsterisk(): boolean {
        return this.currentClient === 'velbert' || this.currentClient === 'ele';
    }

    get contract(): Record<any, any> {
        const tariff = this.$store.getters['tariff/getState'](
            this.$store.state.contracts.contractId
        );
        if (tariff) {
            return tariff.contract;
        }
        return {
            deliveryAddress: {
                firstname: null,
            },
        };
    }

    get firstname(): string | null {
        return this.contract.deliveryAddress.firstname || null;
    }

    get isAuthPlusMaximumAgePassword() {
        return this.customerDetails.isAuthPlusMaximumAgePassword;
    }

    get tariffList(): Record<any, any> {
        if (
            this.currentClient === 'bsw' &&
            this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            )
        ) {
            return this.$store.getters['tariff/getState'](
                this.$store.state.contracts.contractId
            ).oldList;
        }

        return [];
    }

    get showAdsPopup(): boolean {
        if (!this.customerDetailsLoaded) {
            return false;
        }

        return this.showAgreeAdsPopup && this.optInBannerActive;
    }

    @Watch('$store.state.contracts.contractId')
    protected contractIdLoaded(value) {
        if (this.currentClient === 'bsw' && value) {
            this.$store.dispatch('customer/getCommunicationProfiles');
            this.$store.dispatch('tariff/list', value).then(() => {
                const featureTariff = this.tariffList.find((item) => {
                    const todayDate = moment();
                    const futureDate = moment(item.activeFrom, 'YYYY-MM-DD');

                    return !todayDate.isAfter(futureDate);
                });

                if (featureTariff) {
                    this.$store.dispatch('dashboard/showTariffOverview');
                }
            });
        }
    }

    @Watch('isAuthPlusMaximumAgePassword')
    protected changeAuthPlusMaximumAgePassword() {
        this.showMessageModal = this.isAuthPlusMaximumAgePassword;
    }

    @Watch('customerDetails.adsMail')
    @Watch('adsPopupDate')
    protected setAgreeAdsPopup(): void {
        const currentDate = moment();
        const timestamp = Number(this.adsPopupDate);

        this.showAgreeAdsPopup =
            !this.customerDetails.adsMail &&
            (!timestamp || currentDate.diff(moment(timestamp), 'months') >= 1);
    }

    @Watch('customerDetails', { deep: true })
    protected handleCustomerDetailsLoaded(): void {
        this.customerDetailsLoaded = true;
    }

    @Watch('settings.tariffChange')
    @Watch('$store.state.contracts.contractId')
    protected settingsUpdatedAndContractIdSet(): void {
        if (
            this.settings.tariffChange &&
            this.$store.state.contracts.contractId !== null
        ) {
            this.$store.dispatch(
                'tariff/forChange',
                this.$store.state.contracts.contractId
            );
            this.$store.dispatch(
                'tariff/forRecommend',
                this.$store.state.contracts.contractId
            );
        }
    }

    @Watch('$store.state.dashboard.tariffOverview')
    protected handleOverview(value): void {
        this.showGroup4.showTariffOverview = value;
    }

    protected dataCorrectionDone(hide: boolean): void {
        if (hide) {
            setTimeout(() => {
                this.showCustomerDataCorrectionWidget = false;
            }, 3000);
        }
    }

    // Methods
    protected toggleGroup1(key: any): void {
        const oldState = this.showGroup1[key];
        this.closeGroup1();
        this.showGroup1[key] = oldState ? false : true;
    }
    protected toggleGroup2(key: any): void {
        const oldState = this.showGroup2[key];
        this.closeGroup2();
        this.showGroup2[key] = oldState ? false : true;
    }
    protected toggleGroup3(key: any): void {
        const oldState = this.showGroup3[key];
        this.closeGroup3();
        this.showGroup3[key] = oldState ? false : true;
    }
    protected toggleGroup4(key: any): void {
        const oldState = this.showGroup4[key];
        this.closeGroup4();
        this.showGroup4[key] = !oldState;
    }

    protected closeGroup1(): void {
        this.showGroup1.depositChangeWidget = false;
        this.showGroup1.accountTransactionsWidget = false;
        this.showGroup1.meterSubmitWidget = false;
    }

    protected forceCloseGroup1() {
        this.closeGroup1();
        const index = this.elements['elli*'].indexOf(
            'open-meter-submit-one-month-before-due'
        );
        if (index > -1) {
            this.elements['elli*'].splice(index, 1);
        }
    }

    protected closeGroup2(): void {
        this.showGroup2.changeBillingDataWidget = false;
        this.showGroup2.changeCustomerDataWidget = false;
        this.showGroup2.changePaymentDataWidget = false;
    }
    protected closeGroup3(): void {
        this.showGroup3.tariffDetailsWidget = false;
    }
    protected closeGroup4(): void {
        this.showGroup4.showCancellationWidget = false;
        this.showGroup4.showDepositOverview = false;
        this.showGroup4.showMoveWidget = false;
        this.showGroup4.showTariffChange = false;
        this.showGroup4.showTariffOverview = false;
        this.showGroup4.revokeWidget = false;
        this.showGroup4.showEnergyCompoundWidget = false;
        this.showGroup4.showFuturePaymentDetailsOverviewWidget = false;
        this.showGroup4.showTariffRecommendationWidget = false;
        this.showGroup4.showDonation = false;
    }

    protected closeRecommendationWidget(): void {
        this.forceCloseRecommendationWidget = true;
    }

    protected toggleSidebar(): void {
        this.toggleSidebarState = !this.toggleSidebarState;
    }

    @Watch('$store.state.settings.correctCustomerDataGridActiv')
    @Watch('contractStatus.statusCode')
    @Watch('firstname')
    protected async onContractStatusCodeOrGridActivChange(): Promise<void> {
        if (
            this.settings.correctCustomerDataGridActiv &&
            this.$store.state.contracts.contractId &&
            this.contract.deliveryAddress.firstname &&
            this.shouldDisplayWidgetByContractStatus(
                'customerDataCorrectionWidget'
            )
        ) {
            this.dataCorrectionRequiredResponse = (
                await contractsProvider.dataCorrectionRequired(
                    this.$store.state.contracts.contractId
                )
            ).data;

            if (this.dataCorrectionRequiredResponse.success) {
                this.showCustomerDataCorrectionWidget = true;
            }
        }
    }

    @Watch('showGroup1.depositChangeWidget')
    protected onDepositChangeWidgetChange(newVal: boolean): void {
        if (!newVal) {
            this.showGroup4.showDepositOverview = false;
        }
    }

    private schweizstromSidebarMobileInitiallyClosed(): void {
        const deviceWidthMobile = 1024;
        if (
            this.currentClient === 'schweizstrom' &&
            window.innerWidth <= deviceWidthMobile
        )
            this.toggleSidebarState = true;
    }

    protected mounted(): void {
        this.adsPopupDate = localStorage.getItem('wd-ads-popup-date');
        this.setAgreeAdsPopup();

        if (this.currentClient === 'demo') {
            const script = document.querySelector(
                'script[src="https://app.pathadvice.at/embed.js"]'
            );
            if (script === null) {
                const pathadvice = document.createElement('script');
                pathadvice.async = true;
                pathadvice.type = 'text/javascript';
                pathadvice.src = 'https://app.pathadvice.at/embed.js';
                pathadvice.id = 'UlLpIcbkyhBsZ8xa8FNr';
                document.head.appendChild(pathadvice);
            }
        }

        this.schweizstromSidebarMobileInitiallyClosed();
    }

    protected sendAdsData(agree: boolean): void {
        if (agree) {
            this.$store.dispatch('customer/confirmAdsSending');
        } else if (this.$store.state.settings.optInAllowDeclineButton) {
            customerProvider.declineAdsSending().then((res) => {
                if (res.data.success) {
                    this.$store.commit('settings/SET_SETTING', {
                        key: 'optOut',
                        value: true,
                    });
                }
            });
        }

        this.adsPopupDate = moment().valueOf().toString();
        localStorage.setItem('wd-ads-popup-date', this.adsPopupDate);
    }

    /**
     * No longer used anywhere
     * @deprecated
     */
    private toggleOpenChangePasswordModal(): void {
        this.showChangePasswordModal = !this.showChangePasswordModal;
    }

    /**
     * No longer used anywhere
     * @deprecated
     */
    private toggleOpenDeleteAccountModal() {
        this.showDeleteAccountModal = !this.showDeleteAccountModal;
    }

    /**
     * No longer used anywhere
     * @deprecated
     */
    private toggleOpenMessageModal() {
        this.showMessageModal = !this.showMessageModal;
    }

    /**
     * No longer used anywhere
     * @deprecated
     */
    private toggleOpenTwoFactorAuthenticationModal() {
        this.showTwoFactorAuthenticationModal =
            !this.showTwoFactorAuthenticationModal;
    }
}
