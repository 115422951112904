import 'reflect-metadata';
import { Container } from 'inversify';
import SERVICES from './Services';

// Interfaces
import AddOn from '../interfaces/AddOn';
import Auth from '../interfaces/Auth';
import Bonus from '@/interfaces/Bonus';
import Contracts from '../interfaces/Contracts';
import Contact from '../interfaces/Contact';
import Customer from '../interfaces/Customer';
import Reading from '../interfaces/Reading';
import Payment from '../interfaces/Payment';
import Tariff from '../interfaces/Tariff';
import Settings from '../interfaces/Settings';
import Format from '../interfaces/Format';
import Translation from '../interfaces/Translation';
import Map from '../interfaces/Map';
import Meter from '../interfaces/Meter';
import Survey from '../interfaces/Survey';
import Tracking from '../interfaces/Tracking';
import Commodity from '@/interfaces/Commodity';
import VelbertAuth from '@/interfaces/VelbertAuth';
import Language from '../interfaces/Language';
import Verify from '@/interfaces/Verify';
import Files from '@/interfaces/Files';

// Providers
import AuthProvider from '../providers/AuthProvider';
import AddOnProvider from '../providers/AddOnProvider';
import BonusProvider from '../providers/BonusProvider';
import ContractsProvider from '../providers/ContractsProvider';
import ContactProvider from '../providers/ContactProvider';
import CustomerProvider from '../providers/CustomerProvider';
import ReadingProvider from '../providers/ReadingProvider';
import PaymentProvider from '../providers/PaymentProvider';
import TariffProvider from '../providers/TariffProvider';
import SettingsProvider from '../providers/SettingsProvider';
import FormatProvider from '../providers/FormatProvider';
import TranslationProvider from '../providers/TranslationProvider';
import MapProvider from '../providers/MapProvider';
import MeterProvider from '../providers/MeterProvider';
import SurveyProvider from '../providers/SurveyProvider';
import TrackingProvider from '../providers/TrackingProvider';
import CommodityTypeProvider from '@/providers/CommodityTypeProvider';
import AuthVelbertProvider from '@/providers/AuthVelbertProvider';
import LanguageProvider from '../providers/LanguageProvider';
import VerifyProvider from '@/providers/VerifyProvider';
import FilesProvider from '@/providers/FilesProvider';
import EmailProvider from '@/providers/MailProvider';
import IEmailProvider from '@/interfaces/Mails';

// Container
const iocContainer = new Container();

// Bind Providers to Interfaces
iocContainer.bind<AddOn>(SERVICES.ADDON).to(AddOnProvider);
iocContainer.bind<Auth>(SERVICES.AUTH).to(AuthProvider);
iocContainer.bind<Bonus>(SERVICES.BONUS).to(BonusProvider);
iocContainer
    .bind<VelbertAuth>(SERVICES.AUTH_WAERMEENERGIE)
    .to(AuthVelbertProvider);
iocContainer.bind<Contracts>(SERVICES.CONTRACTS).to(ContractsProvider);
iocContainer.bind<Contact>(SERVICES.CONTACT).to(ContactProvider);
iocContainer.bind<Customer>(SERVICES.CUSTOMER).to(CustomerProvider);
iocContainer.bind<Reading>(SERVICES.READING).to(ReadingProvider);
iocContainer.bind<Payment>(SERVICES.PAYMENT).to(PaymentProvider);
iocContainer.bind<Tariff>(SERVICES.TARIFF).to(TariffProvider);
iocContainer.bind<Settings>(SERVICES.SETTINGS).to(SettingsProvider);
iocContainer.bind<Format>(SERVICES.FORMAT).to(FormatProvider);
iocContainer.bind<Translation>(SERVICES.TRANSLATION).to(TranslationProvider);
iocContainer.bind<Map>(SERVICES.MAP).to(MapProvider);
iocContainer.bind<Meter>(SERVICES.METER).to(MeterProvider);
iocContainer.bind<Survey>(SERVICES.SURVEY).to(SurveyProvider);
iocContainer.bind<Tracking>(SERVICES.TRACKING).to(TrackingProvider);
iocContainer.bind<Commodity>(SERVICES.COMMODITY).to(CommodityTypeProvider);
iocContainer.bind<Language>(SERVICES.LANGUAGE).to(LanguageProvider);
iocContainer.bind<Verify>(SERVICES.VERIFY).to(VerifyProvider);
iocContainer.bind<Files>(SERVICES.FILES).to(FilesProvider);
iocContainer.bind<IEmailProvider>(SERVICES.MAIL).to(EmailProvider);

export default iocContainer;
